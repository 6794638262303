import React from "react";
import "../../css/menu.css";
import { Link } from 'react-router-dom';

import '../../css/animationEffect.css';
import ButtonSound from '../../sounds/Start.wav';

function MenuComponent ({ setOpenModal }) {
  const playSound = () => {
    const audio = new Audio(ButtonSound);
    audio.play();
  }
  return (
    <div className="modalBackground" >
      <div className="modalContainer" >

        <div style={{position:'relative', top:'1.25rem'}}>
        <div className="title">
          <h5>Do you want to leave the game?</h5>
        </div>
        <div className="body">
          <p>The progress will be lost and you will need to start it again to continue this campaign.</p>
        </div>
        <div className="footer">
        <Link to = '/'><button style={styles.leaveButton}  className="hoverEnlarge" onClick={playSound}>LEAVE</button> </Link>
          <button style={styles.cancelButton} onClick={() => {setOpenModal(false);playSound();}}  className="hoverEnlarge">CANCEL</button>
        </div>
        </div>

      </div>
    </div>
  );
}

export default MenuComponent;

const styles={
    leaveButton:{
        width: '9rem', // Converted from '144px'
        height: '3rem', // Converted from '48px'
        borderRadius: '1.5rem', // Converted from '24px'
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '1.5rem', // Converted from '24px'
        lineHeight: '1.543rem', // Converted from '24.29px'
        textAlign: 'center',
        border: '1px solid #FFFFFF',
        backgroundColor:'#EB5757',
        color:'#FFFFFF',
        position:'relative',
        right:'0.313rem', // Converted from '5px'
    },
    cancelButton:{
        width: '9rem', // Converted from '144px'
        height: '3rem', // Converted from '48px'
        borderRadius: '1.5rem', // Converted from '24px'
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '1.5rem', // Converted from '24px'
        lineHeight: '1.543rem', // Converted from '24.29px'
        textAlign: 'center',
        border: '1px outset #FABA4E',
        backgroundColor:'#DD7C2B',
        color:'#FFFFFF',
        position:'relative',
        left:'0.313rem', // Converted from '5px'
    }
}



//origin version

// import React from "react";
// import "../../css/menu.css";
// import { Link } from 'react-router-dom';

// function MenuComponent ({ setOpenModal }) {
//   return (
//     <div className="modalBackground" >
//       <div className="modalContainer" >

//         <div style={{position:'relative', top:'20px'}}>
//         <div className="title">
//           <h5>Do you want to leave the game?</h5>
//         </div>
//         <div className="body">
//           <p>The progress will be lost and you will need to start it again to continue this campaign.</p>
//         </div>
//         <div className="footer">
//         <Link to = '/'><button style={styles.leaveButton}>LEAVE</button> </Link>
//           <button style={styles.cancelButton} onClick={() => {setOpenModal(false);}}>CANCEL</button>
//         </div>
//         </div>

//       </div>
//     </div>
//   );
// }

// export default MenuComponent;

// const styles={
//     leaveButton:{
//         width: '144px',
//         height: '48px',
//         borderRadius: '24px',
//         fontFamily: 'Karantina, system-ui',
//         fontWeight: '400',
//         fontSize: '24px',
//         lineHeight: '24.29px',
//         textAlign: 'center',
//         border: '1px solid #FFFFFF',
//         backgroundColor:'#EB5757',
//         color:'#FFFFFF',
//         position:'relative',
//         right:'5px',
//     },
//     cancelButton:{
//         width: '144px',
//         height: '48px',
//         borderRadius: '24px',
//         fontFamily: 'Karantina, system-ui',
//         fontWeight: '400',
//         fontSize: '24px',
//         lineHeight: '24.29px',
//         textAlign: 'center',
//         border: '1px outset #FABA4E',
//         backgroundColor:'#DD7C2B',
//         color:'#FFFFFF',
//         position:'relative',
//         left:'5px',
//     }
// }
