import React from 'react';
import { Link } from 'react-router-dom';

const AppButton = ({ text, color, to, handleStartGame }) => {

  const handleClick = () => {
    if(handleStartGame != null){
      handleStartGame();
    }
    
  };

  return (
    <div>
    <Link to={to}>
      <button className={`btn btn-${color}`} onClick={handleClick}>
        {text}
      </button>
    </Link>
    </div>

  );
};

export default AppButton;