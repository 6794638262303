import React,{ useEffect }  from 'react';
import BigText from './BigText';

import { WOW } from 'wowjs';
import 'animate.css';

const Overlay = () => {
    useEffect(() => {
        new WOW().init();
      }, []);
    return (
        <div style={styles.square} >
            <BigText mainText={true} />
            <h1 className="wow animate__animated animate__bounceInDown" style={style.subtext}>SETTLEMENTS</h1>
        </div>
    );
};

export default Overlay;

const baseFontSize = 16; // Set the base font size of your application

const styles = {
    square: {
        width: '25rem', // 400px converted to rem
        height: '13rem', 
        padding: '1.25rem', // 20px converted to rem
        textAlign: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#360F0E',
    },
};

const style = {
    subtext: {
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '4.8125rem', // 77px converted to rem
        color: 'white',
        lineHeight: '0.9375rem', // 15% converted to rem
    },
};



//original version

// import React from 'react';
// import BigText from './BigText';

// const Overlay = () => {
//     return (
//         <div style={styles.square}>
//             <BigText mainText={true} />
//             <h1 style={style.subtext}>SETTLEMENTS</h1>
//         </div>
//     )
//   };
  

// export default Overlay;

// const styles = {
//       square: {
//       width: '400px',
//       height: '220px',
//       padding: '20px',
//       textAlign: 'center',
//       backgroundSize: 'cover',
//       backgroundPosition: 'center',
//       backgroundColor: '#360F0E',
//     }
// }

// const style = {
//   subtext:{
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '77px',
//     color: 'white',
//     lineHeight: '15%',
//   }
// }

