import React from 'react';
import backgroundImage from '../../images/IntroHeader.png';

const baseFontSize = 16; // Set your base font size here

const IntroOverlay = () => {
  return (
    <div style={styles.container}>
    </div>
  )
};

export default IntroOverlay;

const styles = {
  container: {
    width: '25rem', // 400px / baseFontSize
    height: '18.75rem', // 300px / baseFontSize
    padding: '1.25rem', // 20px / baseFontSize
    textAlign: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#f0f0f0',
  },
}



//origin version

// import React from 'react';
// import backgroundImage from '../../images/IntroHeader.png';

// const IntroOverlay = () => {
//     return (
//         <div style={styles.container}>
//         </div>
//     )
//   };

//   export default IntroOverlay;

//   const styles = {
//     container: {
//       width: '400px',
//       height: '300px',
//       padding: '20px',
//       textAlign: 'center',
//       backgroundImage: `url(${backgroundImage})`,
//       backgroundSize: 'cover',
//       backgroundPosition: 'center',
//       backgroundColor: '#f0f0f0',
//     },
// }