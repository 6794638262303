import React,{useEffect} from 'react';
import backgroundImage from '../../images/strongZombie.png';
import Arm from '../../images/arm.png';
import Fangs from '../../images/fangs.png';

import { WOW } from 'wowjs';
import 'animate.css';

import ButtonSound from '../../sounds/Start.wav';
import '../../css/animationEffect.css';

const StrongZombieOverlay = () => {

  useEffect(() => {
    new WOW().init();
  }, []);
  return (
    //the div down below must not delete, will error 
    <div>
      <div style={overlay}></div>
      <div style={styles.container} className={`wow animate__animated animate__fadeIn `} data-wow-delay = "0s">
          <div style={styles.text}>Strong Zombie</div>
          <img src={Fangs} alt="Fangs" style={image.fangs}/>
          <span style={image.number}>8</span>
          <img src={Arm} alt="Arm" style={image.arm}/>
      </div>
    </div>
  );
};

export default StrongZombieOverlay;

const overlay = {
  width: '400px',
  height: '60px',
  backgroundColor: '#000000',
};

const image = {
    arm:{
        width:'40px',
        height:'40px',
        position: 'relative',
        top: '260px',
        left:'40px',
    },
    fangs:{
        width:'40px',
        height:'40px',
        position: 'relative',
        top: '260px',
        right:'200px',
    },
    number:{
        width: '14px',
        height: '49px',
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '48px',
        color: '#FFFFFF',
        position: 'relative',
        top: '243px',
        right:'190px',
    }
}

const styles = {
  container: {
    width: '400px',
    height: '340px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#000000',
  },
  text: {
    width: '172px',
    height: '49px',
    fontFamily: 'Karantina',
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '48.58px',
    textAlign: 'center',
    color: '#FFFFFF',
    position: 'relative',
    top:'-69px',
    left: '40px',
  },
};


