import React from 'react';

const NumberOfPlayerOverlay = () => {
    return (
        <div style={styles.square}>
            <h1 style={style.numberOfPlayerHeader}>NUMBER OF PLAYERS</h1>
        </div>
    );
};

export default NumberOfPlayerOverlay;

const baseFontSize = 16; // Set the base font size of your application

const styles = {
    square: {
        width: '25rem', // 400px converted to rem
        height: '9.375rem', 
        padding: '1.25rem', // 20px converted to rem
        textAlign: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#2D0B0A',
    },
};

const style = {
    numberOfPlayerHeader: {
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '3rem', // 48px converted to rem
        color: 'white',
        lineHeight: '3.75rem', // 60px converted to rem
    },
};



//original version

// import React from 'react';

// const NumberOfPlayerOverlay = () => {
//     return (
//         <div style={styles.square}>
//             <h1 style={style.numberOfPlayerHeader}>NUMBER OF PLAYERS</h1>
//         </div>
//     )
//   };

//   export default NumberOfPlayerOverlay;

//   const styles = {
//     square: {
//     width: '400px',
//     height: '150px',
//     padding: '20px',
//     textAlign: 'center',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     backgroundColor: '#2D0B0A',
//   }
// }

// const style = {
//     numberOfPlayerHeader:{
//       fontFamily: 'Karantina, system-ui',
//       fontWeight: '400',
//       fontSize: '48px',
//       color: 'white',
//       lineHeight: '60px',
//     }
//   }