import React, { useState, useRef, useEffect } from 'react';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import '../../css/OswaldFont.css';
import Button from 'react-bootstrap/Button';
import characterOne from '../../images/Player1_small.png';
import characterTwo from '../../images/Player2_small.png';
import characterThree from '../../images/Player3_small.png';
import characterFour from '../../images/Player4_small.png';
import { Link } from 'react-router-dom';

const ContinueCampaignContent = () => {
  const handleScroll = (event) => {
    // You can perform any custom scrolling logic here
    console.log('Scrolling...');
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    new SimpleBar(scrollRef.current);
  }, []);

  const container = {
        width: '350px',
        height: '473px',
        borderRadius: '4px',
        border: '1px solid #E0E0E0',
        gap: '24px',
        backgroundColor: '#FFFFFF1A',
        position:'relative',
        left:'5px',
  };

  return (
    <div style={ContinueCampaignComponent}>
      <div>
        <div
          style={{
            height: '491px',
            marginBottom: '20px',
          }}
          ref={scrollRef}
          onScroll={handleScroll}
        >
        <div>
          <div style={container}>

          <div class="container" style={{position:'relative',left:'13px',}}>
                <div class="row">
                        <div class="col" style={styles.smallHeader}>
                        Next scenario
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" style={styles.heading}>
                        The wind from the north
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                        <br />
                                <span style={styles.deleteText}>Accident in the Biotech Lab  </span>
                               
                                <span style={{ borderBottom: '1.5px solid #828282',display: 'inline-block', width: '69px', position:'relative',top:'15px',}}>&nbsp;</span>
                              
                                <span style={styles.date}> 22/12/2023 </span>
                             
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                               <span style={styles.deleteText}>A ship from far away  </span>
                               
                               <span style={{ borderBottom: '1.5px solid #828282',display: 'inline-block', width: '106px', position:'relative',top:'15px',}}>&nbsp;</span>
                            
                               <span style={styles.date}> 14/12/2023 </span>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                                <span style={styles.deleteText}>A not-so-good-decision  </span>
                               
                                <span style={{ borderBottom: '1.5px solid #828282',display: 'inline-block', width: '94px', position:'relative',top:'15px',}}>&nbsp;</span>
                             
                                <span style={styles.date}> 02/12/2023 </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <br />
                            <hr style={{width:'312px', border: '1px solid #E0E0E0'}}/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">

                            <img src={characterOne} alt="characterOne" style={{width:'32px', height:'32px'}}/>

                            <span style={styles.nameText}>DefinitelyNotMiguel89</span>

                            <span style={{ borderBottom: '1.5px solid #828282',display: 'inline-block', width: '76px',position:'relative',left:'10px',}}>&nbsp;</span>

                            <span style={styles.number}>64</span>


                        </div>
                    </div>

                    <div class="row">
                        <div class="col">

                        <img src={characterTwo} alt="characterTwo" style={{width:'32px', height:'32px'}}/>

                        <span style={styles.nameText}>John Doe</span>

                        <span style={{ borderBottom: '1.5px solid #828282',display: 'inline-block', width: '177px',position:'relative',left:'10px',}}>&nbsp;</span>

                        <span style={styles.number}>26</span>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">

                        <img src={characterThree} alt="characterThree" style={{width:'32px', height:'32px'}}/>

                        <span style={styles.nameText}>Samuel Smith</span>

                        <span style={{ borderBottom: '1.5px solid #828282',display: 'inline-block', width: '144px',position:'relative',left:'10px',}}>&nbsp;</span>

                        <span style={styles.number}>21</span>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">

                        <img src={characterFour} alt="characterFour" style={{width:'32px', height:'32px'}}/>

                        <span style={styles.nameText}>Lady Popota</span>

                        <span style={{ borderBottom: '1.5px solid #828282',display: 'inline-block', width: '157px',position:'relative',left:'10px',}}>&nbsp;</span>

                        <span style={styles.number}>12</span>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <Button style={styles.playButton}>PLAY</Button>
                        </div>
                    </div>


            </div>
          </div>

          <br />

          <div style={container}>

            {/* second component */}

           </div>

        </div>

        </div>
        <style>{`
          .simplebar-scrollbar:before {
            background-color: #DD7C2B !important;
            width: 4px !important;
            border-radius: 13px !important;
          }

          .simplebar-scrollbar:before {
            height: 105px !important;
          }


        `}</style>
      </div>
    </div>
  );
};

const ContinueCampaignComponent = {
  width: '370px',
  height: '200px',
  position: 'relative',
  top: '-10px',
  left: '5px',
};

export default ContinueCampaignContent;


const styles={
    smallHeader:{
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '24.29px',
        color: '#E0E0E0',
        position:'relative',
        top:'10px',
    },
    heading:{
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '40px',
        lineHeight: '40.48px',
        color: '#FFFFFF',
        position:'relative',
        top:'20px',
    },
    deleteText:{
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '24.29px',
        color: '#E0E0E0',
        textDecoration: 'line-through',
        width:'165px',
        height:'24px',
        position:'relative',
        top:'20px',
    },
    date:{
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '24.29px',
        color: '#E0E0E0',
        position:'relative',
        top:'20px',
    },
    nameText:{
        fontFamily: 'Oswald',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '29.64px',
        color: '#FFFFFF',
        // textDecoration: 'line-through',
        width:'163px',
        height:'30px',
        position:'relative',
        left:'5px',
        top:'5px',
    },
    number:{
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '32.38px',
        color: '#FFFFFF',
        position:'relative',
        top:'7px',
        left:'16px',
    },
    playButton:{
        width: '312px',
        height: '48px',
        borderRadius: '24px',
        border: '1px solid',
        padding: '12px 8px',
        marginBottom: '8px',
        backgroundColor: '#DD7C2B',
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '24.29px',
        border: '1px outset #FABA4E',
        position:'relative',
        top:'20px',
    }

}