import React,{useEffect} from 'react';
import backgroundImage from '../../images/winning.png'; 
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { WOW } from 'wowjs';
import 'animate.css';

const AttackResultPageOverlay = () => {
    const jsonFromRedux = useSelector((state) => state.jsonStore.combatJson);
    const player = useSelector((state) => state.playerStore.currentPlayer);
    useEffect(() => {
        new WOW().init();
      }, []);
    return(
        <div style={styles.container}>

            <div class="container" style={{position:'relative',top:'150px'}}>
                <div class="row">
                    <div class="col" style={styles.heading}>
                    {/* {player.playerName} */}
                    </div>
                </div>
                <div class="row" style={styles.subText}>
                    <div class="col" className={`wow animate__animated animate__fadeIn`} data-wow-delay = "0s">
                   {jsonFromRedux.battleResult}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AttackResultPageOverlay;


const styles = {
    container: {
      width: '400px',
      height: '335px',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) -6.39%, #000000 100%), url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: '#000000',
    },
    heading:{
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '48px',
        lineHeight: '48.58px',
        textAlign: 'center',
        color:'#FFFFFF',
    },
    subText:{
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '48px',
        lineHeight: '48.58px',
        textAlign: 'center',
        color:'#FFFFFF',
    },
}
