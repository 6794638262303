import React from 'react';
import Button from 'react-bootstrap/Button';
import AttackResultPageContent from './AttackResultPageContent';
import { Link } from 'react-router-dom';

import ButtonSound from '../../sounds/Start.wav';
import '../../css/animationEffect.css';

const AttackResultPageContentBar = () => {
    const playSound = () => {
        const audio = new Audio(ButtonSound);
        audio.play();
      }
    return(
        <div style={ContentComponent}>

        <div className="container">
            <div className = "row">
                <div className="col">
                <AttackResultPageContent />
                </div>
            </div>
            <div className="row">
                <div className="col">
                <Link to = '/MainPage'><Button style={buttonContainer.backButton } className="hoverEnlarge" onClick={playSound}>CLOSE</Button> </Link>
                </div>
            </div>
        </div>

    </div>
    );
};

export default AttackResultPageContentBar;

const ContentComponent = {
    width: '400px',
    height: '365px',
    background: 'linear-gradient(#000000, #5D4241)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
  };

  const buttonContainer ={
    backButton: {
    width: '328px',
    height: '48px',
    borderRadius: '24px',
    border: '1px solid',
    padding: '12px 8px',
    marginBottom: '8px',
    backgroundColor: '#DD7C2B',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '24.29px',
    border: '1px outset #FABA4E',
    position:'relative',
    top: '50px',
    left:'20px',
    },
  };