import React from 'react';
import Icon from '../../images/character.png';


const CharacterIconSmall = ({ numberOfPlayer, backgroundColor }) => {

    const playerFourContainer = {
        width: '32px',
        height: '32px',
        borderRadius: '2.67px',
        backgroundColor: backgroundColor,
    }

    console.log(backgroundColor)
    console.log(playerFourContainer.backgroundColor)
    return (
        <div style={playerFourContainer}>
            <span style={PlayerNumber}>{numberOfPlayer}</span>
            <img src={Icon} style={playerFour} />
        </div>
    )
}

export default CharacterIconSmall

const playerFour = {
    position: 'absolute',
    left: '-7px',
    top: '-2.5px',
    width: '45px',
    height: '45px',
    opacity: '0.6',
    mixBlendMode: 'darken',
    zIndex: '1',
}

const PlayerNumber = {
    width: '7px',
    height: '32px',
    position: 'absolute',
    top: '-8px',
    left: '12.5px',
    color: '#FFFFFF',
    fontSize: '32px',
    fontWeight: '400',
    fontFamily: 'Karantina, system-ui',
    zIndex: '2',  
};

