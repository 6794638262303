import { createSlice } from '@reduxjs/toolkit';

const gameSlice = createSlice({
  name: 'game',
  initialState: {
    numberOfPlayers: 2,
    rewards: [],
    currentOption: 0
  },
  reducers: {
    startGame: (state, action) => {
      state.numberOfPlayers = action.payload;
      // console.log("set" + state.numberOfPlayers)
    },
    addReward: (state, action) => {
      state.rewards.push(action.payload);
    },

    removeReward: (state, action) => {
      state.rewards = [];
    },

    setCurrentOption: (state, action) => {
      state.currentOption = action.payload;
    },
  },
});

export const { startGame,addReward,removeReward,  setCurrentOption} = gameSlice.actions;
export default gameSlice.reducer;