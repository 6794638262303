import React,{ useEffect } from 'react';
import '../../css/bigText.css'

import { WOW } from 'wowjs';
import 'animate.css';

function BigText (props) {
    let topText = props.mainText ? 'mainText': ''
    useEffect(() => {
        new WOW().init();
      }, []);
    return (
    <div>
        <h1 className={`wow animate__animated animate__bounceInDown ${topText}`}>DEADZONE</h1>
    </div>
)}

export default BigText
