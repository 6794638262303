import React,  { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import AppButton from '../uiElements/AppButton';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IntroOverlay from '../uiElements/IntroOverlay';
import IntroContentBar from '../uiElements/IntroContentBar';

import { WOW } from 'wowjs';
import 'animate.css';
import '../../css/animationEffect.css';
import BgSound from '../../sounds/FireSound.mp3';


function IntroPage() {
  const [scenarioData, setScenarioData] = useState(null);
  let audioRef = null;

  const fetchScenarioData = async (jsonData) => {
    try {
      const response = await axios.post('https://vgvqf6srb1.execute-api.ap-east-1.amazonaws.com/dev/scenarioStart', jsonData);
      console.log(response.data)
      setScenarioData(response.data);
    } catch (error) {
      console.error('Error fetching scenario data:', error);
    }
  };

  const scenarioKey = {
    "scenarioKey": "basic"
  };


  const playSound = () => {
    audioRef = new Audio(BgSound);
    audioRef.play();
  }

  const stopSound = () => {
    console.log("Stop music?")
    // if (audioRef) {
      audioRef.pause();
      console.log("Stop music")
      audioRef.currentTime = 0;
    // }
  }
  
  useEffect(() => {
    fetchScenarioData(scenarioKey);
    new WOW().init();
    // setTimeout(() => {
    //   playSound();
    // }, 1000);
    // return () => {
    //   stopSound();
    // };
  }, []);



    return (
      <Container className="wow animate__animated animate__fadeIn" data-wow-delay = "1s" data-wow-duration = "2s">
      <Row>
        <Col sm></Col>
        <Col sm >
          {/* onClick={playSound} */}
          <IntroOverlay />
          <IntroContentBar title={scenarioData && scenarioData.title} text={scenarioData && scenarioData.text} />
        </Col>
        <Col sm></Col>
      </Row>
    </Container>
    );
  }
  
  export default IntroPage;