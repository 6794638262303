import React,{useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import backgroundImage from '../../images/hardwareStore.png';
import backgroundChurchImage from '../../images/church02.jpg';
import styled from 'styled-components';
import NameBox from './Namebox';
import CharacterIconSmall from '../uiElements/CharacterIconSmall';

import { WOW } from 'wowjs';
import 'animate.css';

const LocationPageOverlay = () => {

  const qrCodeResult = useSelector((state) => state.qrcodeStore.qrCodeResult);
  const currentPlayer = useSelector((state) => state.playerStore.currentPlayer);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(backgroundImage);

  const capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string') {
      return 'Please provide a valid string input';
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    new WOW().init();
  }, []);

  useEffect(() => {
    if (qrCodeResult === 'supermarket') {
      setBackgroundImageUrl(backgroundImage);
    } else if (qrCodeResult === 'church') {
      setBackgroundImageUrl(backgroundChurchImage);
    }
  }, [qrCodeResult]);

  return (
    <div style={{ ...styles.container, backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%), url(${backgroundImageUrl})` }}>
      <div style={styles.header}>
        <span>{capitalizeFirstLetter(qrCodeResult.toString())}</span>
      </div>
      <div>
      <CharacterContainer>
        <div className="row">
          <div className="col-4">
            <CharacterIconSmall numberOfPlayer={currentPlayer.id} backgroundColor={currentPlayer.bgColor} />
          </div>
          <div className="col-8">
            <span style={style.numberOfPlayerHeader}>{currentPlayer.playerName}</span>
          </div>
          <div className="col"></div>
        </div>
      </CharacterContainer>
      </div>
    </div>
  );
};

export default LocationPageOverlay;

const styles = {
  container: {
    width: '25rem',
    height: '17.5rem',
    display: 'flex',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#000000',
  },
  header: {
    width: '11.75rem', // 188px / 16px = 11.75rem
    height: '3.063rem', // 49px / 16px = 3.063rem
    fontFamily: 'Karantina',
    fontWeight: 400,
    fontSize: '3rem', // 48px / 16px = 3rem
    lineHeight: '3.036rem', // 48.58px / 16px = 3.036rem
    textAlign: 'center',
    color: '#FFFFFF',
    position: 'relative',
    top: '11.75rem', // 188px / 16px = 11.75rem
    left: '2.813rem', // 45px / 16px = 2.813rem
  },
};

const CharacterContainer = styled.div`
  position: relative;
  top: 15.125rem; // 242px / 16px = 15.125rem
  right: 6.25rem; // 100px / 16px = 6.25rem
`;

const style = {
  numberOfPlayerHeader: {
    fontFamily: 'Oswald',
    fontWeight: '400',
    fontSize: '1.25rem', // 20px / 16px = 1.25rem
    color: 'white',
    lineHeight: '2.188rem', // 35px / 16px = 2.188rem
    textAlign: 'left',
  }
};



//origin version

// import React from 'react';
// import { useSelector, useDispatch } from "react-redux";
// import backgroundImage from '../../images/hardwareStore.png';
// import styled from 'styled-components';
// import NameBox from './Namebox';
// import CharacterIconSmall from '../uiElements/CharacterIconSmall';




// const LocationPageOverlay = () => {

//   const qrCodeResult = useSelector((state) => state.qrcodeStore.qrCodeResult);
//   const currentPlayer = useSelector((state) => state.playerStore.currentPlayer);

//   const capitalizeFirstLetter = (str) => {
//     if (typeof str !== 'string') {
//       return 'Please provide a valid string input';
//     }

//     return str.charAt(0).toUpperCase() + str.slice(1);
//   };
//   return (
//     <div style={styles.container}>
//       <div style={styles.header}>
//         <span>{capitalizeFirstLetter(qrCodeResult.toString())}</span>
//         </div>
//       <CharacterContainer>
//         <div class="row ">
//           <div class="col-4 ">
//             <CharacterIconSmall numberOfPlayer={currentPlayer.id} backgroundColor={currentPlayer.bgColor} />
//           </div>
//           <div class="col-8">
//             <span style={style.numberOfPlayerHeader}>{currentPlayer.playerName}</span>
//             {/* {currentPlayer.playerName} */}
//           </div>
//           <div class="col">
//           </div>
//         </div>
//       </CharacterContainer>
//     </div>
//   );
// };

// export default LocationPageOverlay;

// const styles = {
//   container: {
//     width: '400px',
//     height: '280px',
//     display: 'flex',
//     justifyContent: 'center',
//     // textAlign: 'center',
    
//     backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%), url(${backgroundImage})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     backgroundColor: '#000000',
//   },
//   header: {
//     width: '188px',
//     height: '49px',
//     fontFamily: 'Karantina',
//     fontWeight: 400,
//     fontSize: '48px',
//     lineHeight: '48.58px',
//     textAlign: 'center',
//     color: '#FFFFFF',
//     position: 'relative',
//     top: '188px',
//     left: '45px',
//   },
// };

// const CharacterContainer = styled.div`
//   position: relative;
//   top: 242px;
//   right: 100px;
// `;

// const style = {
//   numberOfPlayerHeader: {
    
//     fontFamily: 'Oswald',
//     fontWeight: '400',
//     fontSize: '20px',
//     color: 'white',
//     lineHeight: '35px',
//     textAlign: 'left',
   
//   }
// }





