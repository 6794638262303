import React, { useState, useRef, useEffect } from 'react';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import '../../css/OswaldFont.css';
import AttackResultPageComponent from './AttackResultPageComponent';
import { useSelector } from 'react-redux';

import { WOW } from 'wowjs';
import 'animate.css';

const AttackResultPageContent = () => {

  const jsonFromRedux = useSelector((state) => state.jsonStore.combatJson);

  const handleScroll = (event) => {
    // You can perform any custom scrolling logic here
    console.log('Scrolling...');
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    new SimpleBar(scrollRef.current);
    new WOW().init();
  }, []);

  const textStyle = {
    fontFamily: 'Oswald',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23.71px',
    color: '#F2F2F2',
  };

  return (
    <div style={container}>
      <div>
        <div
          style={{
            height: '270px',
            marginBottom: '20px',
            position: 'relative',
          }}
          ref={scrollRef}
          onScroll={handleScroll}
        >
          <div style={textStyle} className={`wow animate__animated animate__fadeIn`} data-wow-delay = "0.5s">
          {jsonFromRedux.resultText}

          </div>
          
          <br />
          <AttackResultPageComponent />

        </div>
        <style>{`
          .simplebar-scrollbar:before {
            background-color: #DD7C2B !important;
            width: 4px !important;
            border-radius: 13px !important;
          }


        `}</style>
      </div>
    </div>
  );
};

const container = {
  width: '370px',
  height: '200px',
  position: 'relative',
  top: '-45px',
  left: '5px',
};

export default AttackResultPageContent;