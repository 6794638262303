import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import '../../css/style.css';
import AppButton from '../uiElements/AppButton';
import EncounterButton from '../uiElements/EncounterButton';
import { createPlayer, clearPlayers, removeCurrentPlayer } from '../redux/playersSlice';
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';

import PlayerList from '../uiElements/PlayerList';

import FillBox from '../uiElements/FillBox';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/opacityContainer.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MainPageOverlay from '../uiElements/MainPageOverlay';
import '../../css/OswaldFont.css';
import HamburgerMenu from '../uiElements/HamburgerMenu';
import Button from 'react-bootstrap/Button';
import danger from '../../images/danger.png';
import CharacterIcon from '../uiElements/CharacterIcon';
import NameBox from '../uiElements/Namebox';

import { removeReward } from '../redux/gameSlice';

import { WOW } from 'wowjs';
import 'animate.css';
import '../../css/animationEffect.css';
import ButtonSound from '../../sounds/Start.wav';

const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;

function MainPage() {
  const dispatch = useDispatch();

  const players = useSelector((state) => state.playerStore.players);
  const backgroundColor = ['#00A3D7', '#D70000', '#41A901', '#D7C100'];

  const playSound = () => {
    const audio = new Audio(ButtonSound);
    audio.play();
  }


  useEffect(() => {
     dispatch(removeReward());
   }, []);

  return (
    <Container>
      <Row>
        <Col></Col>
        <Col>
          <MainPageOverlay />
          <div className="container-overlay">
            <Container>
              {players.map((player, index) => (
                <div key={index + 1} className="wow animate__animated animate__fadeIn"  data-wow-duration = "0.35s"> 
                {/* data-wow-delay={`${index-0.9}s`} */}
                  <NameBox key={index + 1} numberOfPlayers={index + 1} backgroundColor={backgroundColor[index]} player={player} routingPage="/ScanLocationPage" checkProperty={true} />
                </div>
              ))}
            </Container>
            <Link to="/ZombieAttackPage">
              <Button style={buttonContainer.zombieAttackButton} className="hoverEnlarge" onClick={playSound} >
                <img src={danger} style={icon.leftIcon} />
                <span>ZOMBIE ATTACK</span>
                <img src={danger} style={icon.rightIcon} />
              </Button>
            </Link>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}

export default MainPage;

const buttonContainer = {
  zombieAttackButton: {
    width: '20rem',
    height: '3rem',
    borderRadius: '1.5rem',
    border: '1px solid',
    padding: '0.75rem 0.5rem',
    backgroundColor: '#DD7C2B',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    border: '1px outset #FABA4E',
    zIndex: '3',
    marginTop: '9.375rem',
    position: 'absolute',
    left: '2.5rem',
    position: 'absolute',
    bottom: '2rem',
    top: '22.5rem',
    left: '12.5rem',
    transform: 'translateX(-50%)',
  },
};

const icon = {
  leftIcon: {
    width: '1.526rem',
    height: '1.481rem',
    position: 'relative',
    top: '-0.3125rem',
    right: '0.9375rem',
  },
  rightIcon: {
    width: '1.526rem',
    height: '1.481rem',
    position: 'relative',
    top: '-0.1875rem',
    left: '0.9375rem',
  },
};



//original version

// import React from 'react';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import { useEffect } from 'react';
// import '../../css/style.css';
// import AppButton from '../uiElements/AppButton';
// import EncounterButton from '../uiElements/EncounterButton'
// import { createPlayer, clearPlayers, removeCurrentPlayer } from '../redux/playersSlice';
// import { useSelector, useDispatch } from "react-redux";
// import { useState } from 'react';

// import PlayerList from '../uiElements/PlayerList';

// import FillBox from '../uiElements/FillBox';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../../css/opacityContainer.css';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import MainPageOverlay from '../uiElements/MainPageOverlay';
// import '../../css/OswaldFont.css';
// import HamburgerMenu from '../uiElements/HamburgerMenu';
// import Button from 'react-bootstrap/Button';
// import danger from '../../images/danger.png';
// import CharacterIcon from '../uiElements/CharacterIcon';
// import NameBox from '../uiElements/Namebox';

// import { removeReward } from '../redux/gameSlice';


// const TitleText = styled.h1`
//   position: absolute;
//   top: ${props => props.top};
//   left: 50%;
//   transform: translateX(-50%);
//   color: white;
//   font-size: ${props => props.fontSize};
//   text-align: center;
//   z-index: 1;
// `;

// function MainPage() {
//   const dispatch = useDispatch();

//   const players = useSelector((state) => state.playerStore.players);
//   const backgroundColor = ['#00A3D7', '#D70000', '#41A901', '#D7C100']


//   useEffect(() => {
//      dispatch(removeReward());
//    }, []);

//   return (
//     <Container>
//       <Row>
//         <Col ></Col>
//         <Col >
//           <MainPageOverlay />
//           <div className="container-overlay">
//             <Container>
//               {players.map((player, index) => (
//                 <div key={index + 1}>
//                 <NameBox key={index + 1} numberOfPlayers={index + 1} backgroundColor={backgroundColor[index]} player = {player} routingPage = "/ScanLocationPage" checkProperty = {true} />
//                 </div>

//               ))}
//             </Container>
//             <Link to = "/ZombieAttackPage">
//             <Button style={buttonContainer.zombieAttackButton}>
//             <img src={danger} style={icon.leftIcon}/>
//             <span>ZOMBIE ATTACK</span>
//             <img src={danger} style={icon.rightIcon}/>
//             </Button>
//             </Link>

//           </div>
//         </Col>
//         <Col ></Col>
//       </Row>
//     </Container>
//   );
// }

// export default MainPage;

// const buttonContainer = {
//   zombieAttackButton: {
//     width: '328px',
//     height: '48px',
//     borderRadius: '24px',
//     border: '1px solid',
//     padding: '12px 8px',
//     backgroundColor: '#DD7C2B',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '24px',
//     lineHeight: '24.29px',
//     border: '1px outset #FABA4E',
//     zIndex: '3',
//     marginTop: '150px',
//     position: 'absolute',
//     left: '40px',

//     position: 'absolute',
//     bottom: '2rem', 
//     top:'22.5rem',
//     left:'12.5rem',
//     transform: 'translateX(-50%)',
//   },
// };

// const icon={
//   leftIcon:{
//     width:'25.41px',
//     height:'23.69px',
//     position:'relative',
//     top:'-5px',
//     right:'15px',
//   },
//   rightIcon:{
//     width:'25.41px',
//     height:'23.69px',
//     position:'relative',
//     top:'-3px',
//     left:'15px',
//   }
// }
