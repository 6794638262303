import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import AppButton from '../uiElements/AppButton';
import EncounterButton from '../uiElements/EncounterButton'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NumberOfPlayerOverlay from '../uiElements/NumberOfPlayerOverlay';
import '../../css/opacityContainer.css';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import backgroundImage from '../../images/opacity-background.png';
import NameBox from '../uiElements/Namebox';
import { useSelector, useDispatch } from "react-redux";

import ButtonSound from '../../sounds/Start.wav';
import '../../css/animationEffect.css';


import FillBox from '../uiElements/FillBox';

const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;

function ZombieAttackPage() {
  const players = useSelector((state) => state.playerStore.players);
  const backgroundColor = ['#00A3D7', '#D70000', '#41A901', '#D7C100']

  const playSound = () => {
    const audio = new Audio(ButtonSound);
    audio.play();
  }
    // ...
    return (
      <Container>
        <Row>
          <Col sm></Col>
          <Col sm>
            <div style={styles.imageContainer}>
              <div style={styles.textContainer}>
                <div style={styles.bigText}>ZOMBIE ATTACK</div>
                <div style={styles.subText}>Choose the player under attack</div>
              </div>
  
              <Container style={RowStyle}>
              {players.map((player, index) => (
                <div key={index + 1}>
                 <NameBox key={index + 1} numberOfPlayers={index + 1} backgroundColor={backgroundColor[index]} player = {player} routingPage = "/EnterValuePage"/>
                </div>

              ))}
            </Container>

            <div style={{position:'absolute',top:'29.3rem'}}>
              <Link to = '/MainPage'><Button style={buttonStyle} className="hoverEnlarge" onClick={playSound}>BACK</Button></Link>
            </div>

            </div>
          </Col>
          <Col sm></Col>
        </Row>
      </Container>
    );
  }

export default ZombieAttackPage;

const CharacterContainer = styled.div`
    position: relative;
`;

const RowStyle = {
  marginBottom: '2.857rem', // Converted from '40px'
  position: 'relative',
  top: '3.571rem', // Converted from '60px'
  left: '-1.429rem', // Converted from '-20px'
};

const squareButton = {
  width: '3rem', // Converted from '48px'
  height: '3rem', // Converted from '48px'
  borderRadius: '0.286rem', // Converted from '4px'
  backgroundColor: '#DD7C2B',
  border: 'none',
};

const iconStyle = {
  width: '1.143rem', // Converted from '24px'
  height: '1.143rem', // Converted from '24px'
  marginTop: '0.357rem', // Converted from '5px'
};

const layout = {
  width: '17.714rem', // Converted from '272px'
  height: '3rem', // Converted from '48px'
  borderRadius: '0.286rem', // Converted from '4px'
  backgroundColor: '#584242',
};

const styles = {
  imageContainer: {
    width: '25rem', // Converted from '400px'
    height: '45.9375rem', 
    padding: '1.429rem', // Converted from '20px'
    textAlign: 'center',
    backgroundImage: `linear-gradient(rgba(45, 11, 10, 0.5), rgba(81, 54, 53, 0.5)), url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'transparent',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  bigText: {
    fontFamily: 'Karantina',
    fontWeight: 400,
    fontSize: '3rem', // Converted from '48px'
    lineHeight: '3.073rem', //Converted from '48.58px'
    textAlign: 'center',
    color: '#FFFFFF',
  },
  subText: {
    fontFamily: 'Karantina',
    fontWeight: 400,
    fontSize: '2rem', // Converted from '32px'
    lineHeight: '2.038rem', // Converted from '32.38px'
    textAlign: 'center',
    color: '#FFFFFF',
  },
};

const buttonStyle = {
  width: '20.5rem', // Converted from '328px'
  height: '3rem', // Converted from '48px'
  borderRadius: '1.143rem', // Converted from '24px'
  border: '1px solid #FFFFFF',
  padding: '0.857rem 0.571rem', // Converted from '12px 8px'
  backgroundColor: '#0000004D',
  fontFamily: 'Karantina',
  fontWeight: 400,
  fontSize: '1.5rem', // Converted from '24px'
  lineHeight: '1.543rem', // Converted from '24.29px'
  color: '#FFFFFF',
  position: 'relative',
  top: '10rem', // Converted from '170px'
};



//origin version

// import React from 'react';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import '../../css/style.css';
// import AppButton from '../uiElements/AppButton';
// import EncounterButton from '../uiElements/EncounterButton'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import NumberOfPlayerOverlay from '../uiElements/NumberOfPlayerOverlay';
// import '../../css/opacityContainer.css';
// import Button from 'react-bootstrap/Button';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCamera } from '@fortawesome/free-solid-svg-icons';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import backgroundImage from '../../images/opacity-background.png';
// import NameBox from '../uiElements/Namebox';
// import { useSelector, useDispatch } from "react-redux";


// import FillBox from '../uiElements/FillBox';

// const TitleText = styled.h1`
//   position: absolute;
//   top: ${props => props.top};
//   left: 50%;
//   transform: translateX(-50%);
//   color: white;
//   font-size: ${props => props.fontSize};
//   text-align: center;
//   z-index: 1;
// `;

// function ZombieAttackPage() {
//   const players = useSelector((state) => state.playerStore.players);
//   const backgroundColor = ['#00A3D7', '#D70000', '#41A901', '#D7C100']
//     // return (
//     //   <div className="App">
//     //   <header className="App-header">
//     //     <div className="deadzone-container">
//     //       <div className="background-image">
//     //         <TitleText top="5vw" fontSize="6vw">ZOMBIE ATTACK </TitleText>
//     //         <TitleText top="25vw" fontSize="2vw">Choose the player under attack </TitleText>
//     //         <TitleText top="30vw" fontSize="6vw">Player 1   <EncounterButton text="GO" color="primary" to="/EnterValuePage" player = "Player 1"/> </TitleText>  
//     //         <TitleText top="50vw" fontSize="6vw">Player 2   <EncounterButton text="GO" color="primary" to="/EnterValuePage" player = "Player 2"/> </TitleText>
//     //         <TitleText top="100vw" fontSize="3vw"> 
//     //         <AppButton text="BACK" color="primary" to="/MainPage" />
//     //         </TitleText>
//     //       </div>
//     //     </div>
//     //   </header>
//     // </div>
//     // );
//     return (
//       <Container>
//         <Row>
//           <Col sm></Col>
//           <Col sm>
//             <div style={styles.imageContainer}>
//               <div style={styles.textContainer}>
//                 <div style={styles.bigText}>ZOMBIE ATTACK</div>
//                 <div style={styles.subText}>Choose the player under attack</div>
//               </div>
  
//               <Container style={RowStyle}>
//               {players.map((player, index) => (
//                 <div key={index + 1}>
//                  <NameBox key={index + 1} numberOfPlayers={index + 1} backgroundColor={backgroundColor[index]} player = {player} routingPage = "/EnterValuePage"/>
//                 </div>

//               ))}
//             </Container>
  
//               <Link to = '/MainPage'><Button style={buttonStyle}>BACK</Button></Link>
//             </div>
//           </Col>
//           <Col sm></Col>
//         </Row>
//       </Container>
//     );
//   }

// export default ZombieAttackPage;

// const CharacterContainer = styled.div`
//     position: relative;
//     `;

//     const RowStyle = {
//         marginBottom: '40px',
//         position: 'relative',
//         top: '60px',
//         left: '-20px',
        
//       };

//       const squareButton = {
//         width: '48px',
//         height: '48px',
//         borderRadius: '4px',
//         backgroundColor: '#DD7C2B',
//         border: 'none',
//       };
      
//       const iconStyle = {
//           width: '24px',
//           height: '24px',
//           marginTop: '5px',
//         };

//       const layout = {
//         width: '272px',
//         height: '48px',
//         borderRadius: '4px',
//         backgroundColor: '#584242',
//       };

//       const styles = {
//         imageContainer: {
//           width: '400px',
//           height: '700px',
//           padding: '20px',
//           textAlign: 'center',
//           backgroundImage: `linear-gradient(rgba(45, 11, 10, 0.5), rgba(81, 54, 53, 0.5)), url(${backgroundImage})`,
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           backgroundColor: 'transparent',
//         },
//         textContainer: {
//           display: 'flex',
//           flexDirection: 'column',
//         },
//         bigText: {
//           fontFamily: 'Karantina',
//           fontWeight: 400,
//           fontSize: '48px',
//           lineHeight: '48.58px',
//           textAlign: 'center',
//           color: '#FFFFFF',
//         },
//         subText: {
//           fontFamily: 'Karantina',
//           fontWeight: 400,
//           fontSize: '32px',
//           lineHeight: '32.38px',
//           textAlign: 'center',
//           color: '#FFFFFF',
//         },
//       };
      
//       const buttonStyle = {
//           width: '328px',
//           height: '48px',
//           borderRadius: '24px',
//           border: '1px solid #FFFFFF',
//           padding: '12px 8px',
//           backgroundColor: '#0000004D',
//           fontFamily: 'Karantina',
//           fontWeight: 400,
//           fontSize: '24px',
//           lineHeight: '24.29px',
//           color: '#FFFFFF',
//           position: 'relative',
//           top: '170px',
          
//       }