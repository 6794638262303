import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import Button from 'react-bootstrap/Button';
import GoOption from './GoOption';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import bullets from '../../images/bullets.png'
import food from '../../images/food.png'
import health from '../../images/health.png'
import fuel from '../../images/fuel.png'
import noise from '../../images/noise.png'
import morale from '../../images/morale.png'
import weapons from '../../images/weapons.png'
import materials from '../../images/weapons.png'
import scientific_research from '../../images/scientific_research.png'
import weapon_designs from '../../images/weapon_designs.png'
import tools from '../../images/tools.png'
import resources from '../../images/resources.png'
import vantage_point from '../../images/vantage_point.png'
import population from '../../images/population.png'
import safety from '../../images/safety.png'

import { setCurrentOption } from '../redux/gameSlice';
import { addReward } from '../redux/gameSlice';
import { updatecurrentPlayerProperty } from '../redux/playersSlice';

import { WOW } from 'wowjs';
import 'animate.css';
import ButtonSound from '../../sounds/Collect.wav';
import '../../css/animationEffect.css';

const playSound = () => {
  const audio = new Audio(ButtonSound);
  audio.play();
}

const Option = ({ index }) => {
  const jsonFromRedux = useSelector((state) => state.jsonStore.json);
  const imageMap = {
    food: food,
    health: health,
    fuel: fuel,
    bullets: bullets,
    noise: noise,
    morale: morale,
    weapons: weapons,
    materials: materials,
    scientific_research: scientific_research,
    weapon_designs: weapon_designs,
    tools: tools,
    resources: resources,
    vantage_point: vantage_point,
    population: population,
    safety: safety
    // Add more key-image mappings as needed
  };

  const dispatch = useDispatch();
  const playSound = () => {
    const audio = new Audio(ButtonSound);
    audio.play();
  }

  const handleButtonClick = () => {
    playSound()
    Object.entries(jsonFromRedux.result.options[index].rewards).forEach(([key, value]) => {
      let evaluatedValue = value;
      if (typeof value !== 'number') {
        evaluatedValue = eval(value);
      }
      if (typeof evaluatedValue === 'number') {
        dispatch(setCurrentOption(index))
        dispatch(addReward({ key, value: evaluatedValue }));
        dispatch(updatecurrentPlayerProperty({ property: key, propertyValue: evaluatedValue }));
      }
    });
  };

  useEffect(() => {
    new WOW().init();
  }, []);
  return (


    <div style={component.container} className={`wow animate__animated animate__fadeIn`} data-wow-delay = "2s">
      <Container>
        <Row>
          <Col md={12} style={{ position: 'relative', top: '5px' }}>
            <div class="container" style={{ position: 'relative', right: '20px' }}>
              <div class="row" style={{ position: 'relative', top: '5px' }}>
                <div class="col">
               <p style={component.text}>{jsonFromRedux && JSON.stringify(jsonFromRedux.result.options[index].button_text, null, 2).replace(/^"|"$/g, '')}</p>
                </div>
              </div>
              <div class="row" style={{ position: 'relative', top: '-5px' }}>
                <div class="col-9" style={{ height: '10px' }}>
                  {jsonFromRedux && Object.entries(jsonFromRedux.result.options[index].rewards).map(([key, value], i, arr) => (
                    <React.Fragment key={key} >
                     
                      <img src={imageMap[key]} alt={key} style={styles.gas} />
                      <span style={{ ...styles.spanOne, color: value < 0 ? 'red' : '#6FCF97' }}>
                        {typeof value === 'string' ? eval(value) : (value > 0 ? `+${value}` : `${value}`)}
                      </span>
                      {i !== arr.length - 1 && <span style={styles.spanTwo}> / </span>}
  
                    </React.Fragment>
                  ))}

                </div>
                <div class="col-3" style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', top: '-20px', left: '30px' }}>
                  <Link to="/EncounterResultPage">
                    <Button style={squareButton} onClick={handleButtonClick} className="hoverEnlarge">
                      <FontAwesomeIcon icon={faArrowRight} style={iconStyle}  />
                    </Button>
                  </Link>

                </div>
              </div>
            </div>
          </Col>
          <Col xs={1}></Col>

        </Row>
      </Container>


    </div>




  );
};

export default Option;

const component = {
  container: {
    width: '355px',
    height: 'auto',
    borderRadius: '4px',
    border: '1px solid #828282',
    padding: '8px 8px 0px 16px',
    marginBottom: '20px',
    gap: '16px',
    backgroundColor: '#333333',
    
    // position: 'relative',
    // left:'10px',

  },
  text: {
    width: '280px',
    height: '24px',
    fontFamily: 'Oswald',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '23.71px',  
    textAlign: 'left',
    color: '#FFFFFF',
  }
}

const styles = {
  gas: {
    width: '30px',
    height: '30px',
    position: 'relative',
    top: '-3px',
    marginLeft: '5px',
    // marginRight: '5px'
  },
  sound: {
    width: '30px',
    height: '30px',
    position: 'relative',
    top: '-3px',
    left: '5px',
  },
  spanOne: {
    width: '19px',
    height: '30px',
    fontFamily: 'Oswald',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '29.64px',
    textAlign: 'left',
    color: '#6FCF97',
    position: 'relative',
    left: '5px',
  },
  spanTwo: {
    width: '8px',
    height: '30px',
    fontFamily: 'Oswald',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '29.64px',
    textAlign: 'center',
    color: '#828282',
    position: 'relative',
    left: '5px',

  },
  spanThree: {
    width: '19px',
    height: '30px',
    fontFamily: 'Oswald',
    fontWeight: '800',
    fontSize: '20px',
    lineHeight: '29.64px',
    textAlign: 'center',
    color: '#FF6262',
    position: 'relative',
    left: '8px',
    top: '2px',
  },
  spanFour: {
    width: '19px',
    height: '30px',
    fontFamily: 'Oswald',
    fontWeight: '800',
    fontSize: '20px',
    lineHeight: '29.64px',
    textAlign: 'center',
    color: '#F2F2F2',
    position: 'relative',
    left: '8px',
    top: '2px',
  }
};

const squareButton = {
  width: '48px',
  height: '48px',
  borderRadius: '4px',
  backgroundColor: '#DD7C2B',
  border: 'none',
};

const iconStyle = {
  width: '24px',
  height: '24px',
  marginTop: '5px',
};