import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/OswaldFont.css';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { useEffect } from 'react';
import axios from 'axios';
import { storeCombatJson } from '../redux/jsonSlice';
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from 'simplebar';
import { useRef } from 'react';
import PlayerBox from './PlayerBox';
import { useNavigate } from 'react-router-dom';

import ButtonSound from '../../sounds/Character.wav';
import ButtonSound02 from '../../sounds/Start.wav';
import '../../css/animationEffect.css';

const StrongZombieContent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const players = useSelector((state) => state.playerStore.players);
    const playerArray = [];

    players.map(player => {
        playerArray.push(player);
    });

    const backgroundColor = ['#00A3D7', '#D70000', '#41A901', '#D7C100']

    const initialDefenseValues = {};
    for (let i = 1; i <= players.length; i++) {
        initialDefenseValues[`defenseValue${i}`] = '';
    }
    const [defenseValues, setDefenseValues] = useState(initialDefenseValues);


    const [combatData, setCombatData] = useState({
        monsterName: 'Gargantuan Horror',
        combatResults: []
    });

    const playSound = () => {
        const audio = new Audio(ButtonSound);
        audio.play();
      }

      const playSound02 = () => {
        const audio = new Audio(ButtonSound02);
        audio.play();
      }

    const handleValueChange = function (amount, textbox) {
        return function () {
            setDefenseValues(function (prevValues) {
                const newValues = { ...prevValues };
                newValues[textbox] = (parseInt(prevValues[textbox] || '0') + amount).toString();
                return newValues;
            });
        };
    };

    const handleResolve = function () {
        playSound02()
        const newResults = Object.keys(defenseValues).map((textbox, index) => ({
            //playerName: 'Player' + (index + 1),
            playerName: '' + playerArray[index].playerName,
            value: defenseValues[textbox],
            result: Math.random() < 0.5 ? 'Defeated' : 'Victorious'
        }));



        setCombatData(function (prevData) {
            return {
                ...prevData,
                combatResults: [...prevData.combatResults, ...newResults]
            };
        });

        console.log(combatData);

    };

    // const scrollRef = useRef(null);
    // const handleScroll = (event) => {
    //     // You can perform any custom scrolling logic here
    //     console.log('Scrolling...');
    // };

    useEffect(() => {
        // new SimpleBar(scrollRef.current);
        const sendApiRequest = async () => {
            if (combatData.combatResults.length > 0) {
                console.log("Update");
                console.log(combatData);

                try {
                    const response = await axios.post('https://vgvqf6srb1.execute-api.ap-east-1.amazonaws.com/dev/combat', combatData);
                    dispatch(storeCombatJson(response.data));
                    navigate('/AttackResultPage');
                } catch (error) {
                    console.error('Error in combat:', error);
                }
            }
        };

        sendApiRequest();
    }, [combatData, dispatch]);



    return (
        <div style={styles.container}>
            <div style={styles.textContainer}>
                <p style={styles.bigText}>Attack description lorem ipusm dolor sit amet bla bla bla bla bla bla bla bla bla bla bla bla bla bla.</p>

            </div>

            <div className="container">
                {/* <div style={{
                    height: '300px',
                    marginBottom: '20px',
                    position: 'relative',
                }}
                    ref={scrollRef}
                    onScroll={handleScroll}
                > */}
                {/* <div> */}

                {Object.keys(defenseValues).map((textbox, index) => (
                    <div className="row" style={buttonContainer.container} key={index}>
                        <PlayerBox numberOfPlayers={index + 1} backgroundColor={backgroundColor[index]} player={playerArray[index]} />

                        <div style={buttonContainer.leftButtonContainer}  onClick={playSound}>
                        <Button style={button.leftButton} onClick={handleValueChange(-1, textbox)}  className="hoverEnlarge"  ><FontAwesomeIcon icon={faMinus} style={{ color: '#F2994A' }} /></Button>
                        </div>

                        {/* Enter value */}
                        <div className="col col-md-6" style={buttonContainer.inputContainer}>

                            <OverlayInput type="text" className="textbox" placeholder="Enter your defense" value={defenseValues[textbox]} onChange={function (e) {
                                setDefenseValues({ ...defenseValues, [textbox]: e.target.value });
                            }}
                                style={{ color: defenseValues[textbox] ? 'black' : 'inherit' }} />
                        </div>
                        <div style={buttonContainer.rightButtonContainer} onClick={playSound}>
                            <Button style={button.rightButton} onClick={handleValueChange(1, textbox)} className="hoverEnlarge"  ><FontAwesomeIcon icon={faPlus} style={{ color: '#F2994A' }} /></Button>
                        </div>
                    </div>
                ))}
                {/* <p style={buttonContainer.text}>Remember to add the modifiers of all your equipment.</p> */}
                {/* </div> */}
                {/* </div> */}
                {/* <style>{`
          .simplebar-scrollbar:before {
            background-color: #DD7C2B !important;
            width: 4px !important;
            border-radius: 13px !important;
          }

          .simplebar-scrollbar:before {
            height: 105px !important;
          }

        `}</style> */}

                <div style={lowerButton.container} className="d-flex flex-row">
                    <Link to="/ZombieAttackPage"><Button style={lowerButton.backButton} className="hoverEnlarge" onClick={playSound02} >BACK</Button></Link>
                    <Button style={lowerButton.resolveButton} onClick={handleResolve} className="hoverEnlarge"  >RESOLVE</Button>
                </div>

                {/* {combatData.combatResults.map((result, index) => (
                    <div key={index}>
                        <p>Player Name: {result.playerName}</p>
                        <p>Defense Value: {result.value}</p>
                        <p>Result: {result.result}</p>
                    </div>
                ))} */}


            </div>
        </div>
    );
};

export default StrongZombieContent;

const styles = {
    container: {
        width: '400px',
        height: '655px',
        background: 'linear-gradient(#000000, #5D4241)'
    },
    textContainer: {
        width: '328px',
        height: '48px',
        position: 'relative',
        top: '25px',
        left: '30px',
    },
    bigText: {
        fontFamily: 'Oswald',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '23.71px',
        color: '#F2F2F2',
    }
};

const buttonContainer = {
    container: {
        width: '328px',
        height: '49px',
        position: 'relative',
        top: '50px',
        left: '30px',
        marginBottom: '70px'
    },

    leftButtonContainer: {
        width: '48px',
        height: '48px',
    },

    inputContainer: {
        width: '216px',
        height: '49px',
        // backgroundColor: 'black',
    },

    rightButtonContainer: {
        width: '48px',
        height: '48px',
    },

    text: {
        fontFamily: 'Oswald',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '17.78px',
        color: '#F2F2F2',
        position: 'relative',
        right: '10px',
        top: '15px',
    }
};

const button = {
    leftButton: {
        width: '48px',
        height: '48px',
        borderRadius: '4px',
        border: '0.86px solid #F2994A',
        backgroundColor: '#0000004D',
        position: 'relative',
        top: '0.5px',
        right: '10px',


    },

    // inputButton:{
    //     width: '216px',
    //     height: '49px',
    //     borderRadius: '4px',
    //     border: '1px solid #F2994A',
    //     backgroundColor: '#FFFFFF',
    //     transform: 'translate(-50%, -50%)',

    // '&:focus': {
    //     outline: 'none',
    //     border: '2px solid #F2994A',
    // },

    //     fontFamily: 'Oswald, sans-serif',
    //     fontWeight: 400,
    //     fontSize: '20px',
    //     lineHeight: '29.64px',
    //     color: '#FFFFFF',
    // },

    rightButton: {
        width: '48px',
        height: '48px',
        borderRadius: '4px',
        border: '0.86px solid #F2994A',
        backgroundColor: '#0000004D',
        position: 'relative',
        top: '0.5px',
        left: '10px',


    },

}


const OverlayInput = styled.input`
  transform: translate(-50%, -50%);
  position: relative;
  top: 25px;
  left: 110px;

  width: 216px;
  height: 49px;
  border-radius: 4px;
  border: 1px solid #F2994A;
  padding: 0px 8px;
  background-color: #FFFFFF;

  &:focus {
    outline: none;
    border: 2px solid #F2994A;
  }

  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 29.64px;
  color: #BDBDBD;
  text-align: center;
`;

const lowerButton = {
    container: {
        marginTop: '170px',
        marginLeft: '20px',
        gap: '10px',
        width: '328px',
        height: '48px',
    },
    backButton: {
        width: '160px',
        height: '48px',
        borderRadius: '24px',
        border: '1px solid #FFFFFF',
        padding: '12px 8px 12px 8px',
        backgroundColor: '#0000004D',
        color: '#FFFFFF',
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '24.29px',
        zIndex: '2',
    },
    resolveButton: {
        width: '160px',
        height: '48px',
        borderRadius: '24px',
        padding: '12px 8px 12px 8px',
        backgroundColor: '#DD7C2B',
        border: '1px outset #FABA4E',
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '24.29px',
        zIndex: '2',
    }
}