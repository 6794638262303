import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import PlayerNamesOverlay from '../uiElements/PlayerNamesOverlay';
import '../../css/opacityContainer.css';
import '../../css/OswaldFont.css';
import Button from 'react-bootstrap/Button';

import React from 'react';
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import AppButton from '../uiElements/AppButton';
import FillBox from '../uiElements/FillBox';

import ButtonSound from '../../sounds/Start.wav';
import '../../css/animationEffect.css';

const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;

function PlayersNamePage() {
  const dispatch = useDispatch();

  const numberOfPlayers = useSelector((state) => state.gameStore.numberOfPlayers);
  const backgroundColor = ['#00A3D7', '#D70000', '#41A901', '#D7C100']

  const renderPlayerTextboxes = () => {
    const textboxes = [];
    for (let i = 0; i < numberOfPlayers; i++) {
      //textboxes.push(<input key={i} type="text" placeholder={`Player ${i + 1} name`} />);
      textboxes.push(<FillBox key={i} numberOfPlayer={i + 1} backgroundColor={backgroundColor[i]} />);
      console.log(numberOfPlayers)
    }
    return textboxes;
  };

  const handleClick = () => {
    // Play the sound
    const audio = new Audio(ButtonSound);
    audio.play();
  };

  
  return (
    <Container>
      <Row>
        <Col sm></Col>
        <Col sm>
          <PlayerNamesOverlay />
          <div className="container-overlay">
            <Container >
              {renderPlayerTextboxes()}
            </Container>
            <div style={buttonContainer.container} className="d-flex flex-row">
              <Link to="/NumberOfPlayersPage">
                <Button style={buttonContainer.backButton} onClick={handleClick} className="hoverEnlarge">BACK</Button>
              </Link>
              <Link to="/IntroPage">
                <Button style={buttonContainer.nextButton} onClick={handleClick} className="hoverEnlarge">NEXT</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col sm></Col>
      </Row>
    </Container>
  );
}

export default PlayersNamePage;

const buttonContainer = {
  container: {
    marginTop: '11.875rem',
    marginLeft: '2.3125rem',
    gap: '0.5rem',
    width: '20.5rem',
    height: '3rem',

    //change the container position
    position: 'absolute',
    bottom: '2rem', 
    top:'20.25rem',
    left:'11.1rem',
    transform: 'translateX(-50%)',

    /* Media Queries */
    '@media (max-width: 320px)': {
      width: '100%', // Adjust the width for iPhone SE
      height: '2.5rem', // Adjust the height for iPhone SE
      marginTop: '8.25rem', // Adjust the margin top for iPhone SE
      marginLeft: '1rem', // Adjust the margin left for iPhone SE
      left: '50%', // Center the container horizontally for iPhone SE
      transform: 'translateX(-50%)',
    },
    '@media (max-width: 414px)': {
      width: '100%', // Adjust the width for iPhone XR
      height: '3.5rem', // Adjust the height for iPhone XR
      marginTop: '8.25rem', // Adjust the margin top for iPhone XR
      marginLeft: '1rem', // Adjust the margin left for iPhone XR
      left: '50%', // Center the container horizontally for iPhone XR
      transform: 'translateX(-50%)',
    },
  },
  backButton: {
    width: '10rem',
    height: '3rem',
    borderRadius: '1.5rem',
    border: '0.0625rem solid #FFFFFF',
    padding: '0.375rem 0.25rem 0.375rem 0.25rem',
    backgroundColor: '#0000004D',
    color: '#FFFFFF',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    zIndex: '2',
    position: 'relative',
  },
  nextButton: {
    width: '10rem',
    height: '3rem',
    borderRadius: '1.5rem',
    padding: '0.375rem 0.25rem 0.375rem 0.25rem',
    backgroundColor: '#DD7C2B',
    border: '0.0625rem outset #FABA4E',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '1.536rem',
    zIndex: '2',
    position: 'absolute',
  }
}






// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Container, Row, Col } from 'react-bootstrap';
// import PlayerNamesOverlay from '../uiElements/PlayerNamesOverlay';
// import '../../css/opacityContainer.css';
// import '../../css/OswaldFont.css';
// import Button from 'react-bootstrap/Button';

// import React from 'react';
// import { useDispatch } from "react-redux";
// import { useSelector } from 'react-redux';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import '../../css/style.css';
// import AppButton from '../uiElements/AppButton';
// import FillBox from '../uiElements/FillBox';

// import ButtonSound from '../../sounds/Start.wav';

// const TitleText = styled.h1`
//   position: absolute;
//   top: ${props => props.top};
//   left: 50%;
//   transform: translateX(-50%);
//   color: white;
//   font-size: ${props => props.fontSize};
//   text-align: center;
//   z-index: 1;
// `;

// function PlayersNamePage() {
//   const dispatch = useDispatch();

//   const numberOfPlayers = useSelector((state) => state.gameStore.numberOfPlayers);
//   const backgroundColor = ['#00A3D7', '#D70000', '#41A901', '#D7C100']

//   const renderPlayerTextboxes = () => {
//     const textboxes = [];
//     for (let i = 0; i < numberOfPlayers; i++) {
//       //textboxes.push(<input key={i} type="text" placeholder={`Player ${i + 1} name`} />);
//       textboxes.push(<FillBox key={i} numberOfPlayer={i + 1} backgroundColor={backgroundColor[i]} />);
//       console.log(numberOfPlayers)
//     }
//     return textboxes;
//   };

//   const handleClick = () => {
//     // Play the sound
//     const audio = new Audio(ButtonSound);
//     audio.play();
//   };


//   return (
//     <Container>
//       <Row>
//         <Col sm></Col>
//         <Col sm>
//           <PlayerNamesOverlay />
//           <div className="container-overlay">
//             <Container >
//               {renderPlayerTextboxes()}
//             </Container>
//             <div style={buttonContainer.container} className="d-flex flex-row">
//               <Link to="/NumberOfPlayersPage">
//                 <Button style={buttonContainer.backButton} onClick={handleClick}>BACK</Button>
//               </Link>
//               <Link to="/IntroPage">
//                 <Button style={buttonContainer.nextButton} onClick={handleClick}>NEXT</Button>
//               </Link>
//             </div>
//           </div>
//         </Col>
//         <Col sm></Col>
//       </Row>
//     </Container>
//   );
// }

// export default PlayersNamePage;

// const buttonContainer = {
//   container: {
//     marginTop: '11.875rem',
//     marginLeft: '2.3125rem',
//     gap: '0.5rem',
//     width: '20.5rem',
//     height: '3rem',

//     //change the container position
//     position: 'absolute',
//     bottom: '2rem', 
//     top:'20.25rem',
//     left:'11.1rem',
//     transform: 'translateX(-50%)',
//   },
//   backButton: {
//     width: '10rem',
//     height: '3rem',
//     borderRadius: '1.5rem',
//     border: '0.0625rem solid #FFFFFF',
//     padding: '0.375rem 0.25rem 0.375rem 0.25rem',
//     backgroundColor: '#0000004D',
//     color: '#FFFFFF',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '1.5rem',
//     lineHeight: '1.536rem',
//     zIndex: '2',
//     position: 'relative',
//   },
//   nextButton: {
//     width: '10rem',
//     height: '3rem',
//     borderRadius: '1.5rem',
//     padding: '0.375rem 0.25rem 0.375rem 0.25rem',
//     backgroundColor: '#DD7C2B',
//     border: '0.0625rem outset #FABA4E',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '1.5rem',
//     lineHeight: '1.536rem',
//     zIndex: '2',
//     position: 'absolute',
//   }
// }



//original version

// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Container, Row, Col } from 'react-bootstrap';
// import PlayerNamesOverlay from '../uiElements/PlayerNamesOverlay';
// import '../../css/opacityContainer.css';
// import '../../css/OswaldFont.css';
// import Button from 'react-bootstrap/Button';


// import React from 'react';
// import { useDispatch } from "react-redux";
// import { useSelector } from 'react-redux';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import '../../css/style.css';
// import AppButton from '../uiElements/AppButton';
// import FillBox from '../uiElements/FillBox';

// import ButtonSound from '../../sounds/Start.wav';


// const TitleText = styled.h1`
//   position: absolute;
//   top: ${props => props.top};
//   left: 50%;
//   transform: translateX(-50%);
//   color: white;
//   font-size: ${props => props.fontSize};
//   text-align: center;
//   z-index: 1;
// `;


// function PlayersNamePage() {
//   const dispatch = useDispatch();

//   const numberOfPlayers = useSelector((state) => state.gameStore.numberOfPlayers);
//   const backgroundColor = ['#00A3D7', '#D70000', '#41A901', '#D7C100']

//   const renderPlayerTextboxes = () => {
//     const textboxes = [];
//     for (let i = 0; i < numberOfPlayers; i++) {
//       //textboxes.push(<input key={i} type="text" placeholder={`Player ${i + 1} name`} />);
//       textboxes.push(<FillBox key={i} numberOfPlayer={i + 1} backgroundColor={backgroundColor[i]} />);
//       console.log(numberOfPlayers)
//     }
//     return textboxes;
//   };

//   const handleClick = () => {
//     // Play the sound
//     const audio = new Audio(ButtonSound);
//     audio.play();

//   };

//   return (
//     <Container>
//       <Row>
//         <Col sm></Col>
//         <Col sm>
//           <PlayerNamesOverlay />
//           <div className="container-overlay">
//             <Container >
//               {renderPlayerTextboxes()}

//             </Container>
//             <div style={buttonContainer.container} className="d-flex flex-row">
//               <Link to="/NumberOfPlayersPage">
//                 <Button style={buttonContainer.backButton} onClick={handleClick}>BACK</Button></Link>
//               <Link to="/IntroPage"><Button style={buttonContainer.nextButton} onClick={handleClick}>NEXT</Button></Link>
//             </div>

//           </div>
//         </Col>
//         <Col sm></Col>
//       </Row>
//     </Container>
//   );
// }

// export default PlayersNamePage;


// const buttonContainer = {
//   container: {
//     marginTop: '190px',
//     marginLeft: '37px',
//     gap: '8px',
//     width: '328px',
//     height: '48px',
//   },
//   backButton: {
//     width: '160px',
//     height: '48px',
//     borderRadius: '24px',
//     border: '1px solid #FFFFFF',
//     padding: '12px 8px 12px 8px',
//     backgroundColor: '#0000004D',
//     color: '#FFFFFF',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '24px',
//     lineHeight: '24.29px',
//     zIndex: '2',
//     position: 'relative',
//   },
//   nextButton: {
//     width: '160px',
//     height: '48px',
//     borderRadius: '24px',
//     padding: '12px 8px 12px 8px',
//     backgroundColor: '#DD7C2B',
//     border: '1px outset #FABA4E',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '24px',
//     lineHeight: '24.29px',
//     zIndex: '2',
//     position: 'absolute',
//   }
// }