import React,{ useRef } from 'react';
import '../../css/style.css'
import { Container, Row, Col } from 'react-bootstrap';
import CharacterIcon from './CharacterIcon';
import styled from 'styled-components';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatePlayer } from '../redux/playersSlice';

import KeyBoardSound from '../../sounds/KeyboardSound.ogg';


const FillBox = ({ numberOfPlayer, backgroundColor }) => {
  const placeholderText = `Enter player ${numberOfPlayer} name`;
  console.log("Fillbox" + backgroundColor)

  const dispatch = useDispatch();
  const [playerName, setplayerName] = useState('');

  const handleNameChange = (event) => {
    setplayerName(event.target.value);
    dispatch(updatePlayer({
      id: numberOfPlayer,
      playerName: event.target.value,
      health: 100, // Assuming initial hp = 100
      bgColor : backgroundColor
    }));
  };

  const keyboardSoundRef = useRef(null);

  const handleKeyPress = () => {
    if (keyboardSoundRef.current) {
      keyboardSoundRef.current.currentTime = 0;
      keyboardSoundRef.current.play();
    }
  };

  return (
    // <div>
    //   <div>
    //     <img src={playerIcon} className='thumbnail'/>
    //   </div>
    //   <input type="text" className="textbox" placeholder=  {placeholderText} defaultValue={playerName} onBlur={handleNameChange}/>
    // </div>

    <Row style={RowStyle}>


      <Col>

        <Container>
          <Row>

            <div className = "col-3">
              <CharacterContainer>
                <CharacterIcon numberOfPlayer={numberOfPlayer} backgroundColor={backgroundColor} />
              </CharacterContainer>
            </div>


            <div className = "col-9">
              <OverlayContainer>
                <OverlayInput type="text" className="textbox" placeholder={placeholderText} defaultValue={playerName} onBlur={handleNameChange} onKeyPress={handleKeyPress}/>   <audio ref={keyboardSoundRef} src={KeyBoardSound} />
              </OverlayContainer>
            </div>


          </Row>
        </Container>
      </Col>
    </Row>

  )
}

export default FillBox;


const RowStyle = {
  marginBottom: '40px',
  position: 'relative',
  top: '75px',
  left: '20px',
}

const CharacterContainer = styled.div`
  position: relative;
  top: -23px;
`;

const OverlayContainer = styled.div`
  position: relative;
`;

// need to change the position to fixed the iphonesize afterward
const OverlayInput = styled.input`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 105px;


  width: 272px;
  height: 46px;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  padding: 0px 16px;
  margin-bottom: 8px;
  background-color: #FFFFFF1A;

  &:focus {
    outline: none;
    border: 2px solid #F2994A;
  }

  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 29.64px;
  color: #FFFFFF;
`;
