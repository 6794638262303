import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import AppButton from '../uiElements/AppButton';
import ViewFinder from '../uiElements/ViewFinder';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { removeQrCodeResult } from '../redux/qrcodeSlice';

import FillBox from '../uiElements/FillBox';
import CharacterIcon from '../uiElements/CharacterIcon';
import NameBox from '../uiElements/Namebox';

import ButtonSound from '../../sounds/Start.wav';
import '../../css/animationEffect.css';


const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;

function ScanLocationPage() {
  const dispatch = useDispatch();
  const player = useSelector((state) => state.playerStore.currentPlayer);
  const playerName = useSelector((state) => state.playerStore.currentPlayer.playerName);
  const playerID = useSelector((state) => state.playerStore.currentPlayer.id);
  const backgroundColor = useSelector((state) => state.playerStore.currentPlayer.bgColor);

  const numberOfPlayers = useSelector((state) => state.gameStore.numberOfPlayers);

  const playSound = () => {
    const audio = new Audio(ButtonSound);
    audio.play();
  }

  useEffect(() => {
    dispatch(removeQrCodeResult());
  }, []);


  return (
    <Container>
      <Row>
        <Col sm></Col>
        <Col sm>
          <div style={styles.container}>
            <ViewFinder />
            <div style={layout}>
              <div className="row">
                <div className="col-3">
                  <CharacterIcon numberOfPlayer={playerID} backgroundColor={backgroundColor} />
                </div>
                <div className="col-9">
                  <span style={style.numberOfPlayerHeader}>{playerName}</span>
                </div>
                <div className="col">
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                </div>
                <div className="col-3">
                  <br></br>
                  <Link to="/MainPage" >
                    <Button style={buttonContainer.backButton} className="hoverEnlarge"  onClick={playSound}>BACK</Button>
                  </Link>
                </div>
                {/* <div className="col-3">
                </div> */}
              </div>
            </div>
          </div>

        </Col>
        <Col sm></Col>
      </Row>
    </Container>

  );
}

export default ScanLocationPage;

const styles = {
  container: {
    width: '25rem', 
    height: '45.9375rem', 
    background: 'linear-gradient(#000000, #5D4241)',
  }
};

const CharacterContainer = styled.div`
  position: relative;
`;

const layout = {
  width: '20.5rem', // 328px divided by 16
  height: '3.0625rem', // 49px divided by 16
  borderRadius: '0.25rem', // 4px divided by 16
  position: 'relative',
  top: '13.75rem', // 220px divided by 16
  left: '0.9375rem', // 15px divided by 16
};

const buttonContainer = {
  container: {
    marginTop: '11.875rem', // 190px divided by 16
    marginLeft: '2.3125rem', // 37px divided by 16
    gap: '0.5rem', // 8px divided by 16
    width: '20.5rem', // 328px divided by 16
    height: '3rem', // 48px divided by 16
  },
  backButton: {
    width: '20.5rem', 
    height: '3rem', // 48px divided by 16
    borderRadius: '1.5rem', // 24px divided by 16
    border: '0.0625rem solid #FFFFFF', // 1px divided by 16
    backgroundColor: '#0000004D',
    color: '#FFFFFF',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.5rem', // 24px divided by 16
    lineHeight: '1.52', // 24.29px divided by 16
    zIndex: '2',
    position:'relative',
    top:'0.5rem',
    right:'4.5rem',
  },
  nextButton: {
    width: '10rem', // 160px divided by 16
    height: '3rem', // 48px divided by 16
    borderRadius: '1.5rem', // 24px divided by 16
    padding: '0.75rem 0.5rem', // 12px divided by 16
    backgroundColor: '#DD7C2B',
    border: '0.0625rem outset #FABA4E', // 1px divided by 16
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.5rem', // 24px divided by 16
    lineHeight: '1.52', // 24.29px divided by 16
    zIndex: '2',
  }
};

const style = {
  numberOfPlayerHeader: {
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.875rem', // 30px divided by 16
    color: 'white',
    lineHeight: '3.125rem', // 50px divided by 16
  }
};


//origin version

// import React, { useEffect } from 'react';
// import styled from 'styled-components';
// import Button from 'react-bootstrap/Button';
// import { Link } from 'react-router-dom';
// import '../../css/style.css';
// import AppButton from '../uiElements/AppButton';
// import ViewFinder from '../uiElements/ViewFinder';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import Container from 'react-bootstrap/Container';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCamera } from '@fortawesome/free-solid-svg-icons';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';



// import { useSelector, useDispatch } from 'react-redux';
// import { useState } from 'react';
// import { removeQrCodeResult } from '../redux/qrcodeSlice';

// import FillBox from '../uiElements/FillBox';
// import CharacterIcon from '../uiElements/CharacterIcon';
// import NameBox from '../uiElements/Namebox';


// const TitleText = styled.h1`
//   position: absolute;
//   top: ${props => props.top};
//   left: 50%;
//   transform: translateX(-50%);
//   color: white;
//   font-size: ${props => props.fontSize};
//   text-align: center;
//   z-index: 1;
// `;

// function ScanLocationPage() {
//   const dispatch = useDispatch();
//   const player = useSelector((state) => state.playerStore.currentPlayer);
//   const playerName = useSelector((state) => state.playerStore.currentPlayer.playerName);
//   const playerID = useSelector((state) => state.playerStore.currentPlayer.id);
//   const backgroundColor = useSelector((state) => state.playerStore.currentPlayer.bgColor);

//   const numberOfPlayers = useSelector((state) => state.gameStore.numberOfPlayers);

//   useEffect(() => {
//     dispatch(removeQrCodeResult());
//   }, []);


//   return (
//     <Container>
//       <Row>
//         <Col sm></Col>
//         <Col sm>
//           <div style={styles.container}>
//             <ViewFinder />
//             <div style={layout}>
//               <div class="row">
//                 <div class="col-3">
//                   <CharacterIcon numberOfPlayer={playerID} backgroundColor={backgroundColor} />
//                 </div>
//                 <div class="col-9">
//                   <span style={style.numberOfPlayerHeader}>{playerName}</span>
//                 </div>
//                 <div class="col">
//                 </div>
//               </div>
//               <div class="row">
//                 <div class="col-3">
//                 </div>
//                 <div class="col-3">
//                   <br></br>
//                   <Link to="/MainPage">
//                     <Button style={buttonContainer.backButton}>BACK</Button>
//                   </Link>
//                 </div>
//                 {/* <div class="col-3">
//                 </div> */}
//               </div>
//             </div>
//           </div>

//         </Col>
//         <Col sm></Col>
//       </Row>
//     </Container>

//   );
// }

// export default ScanLocationPage;

// const styles = {
//   container: {
//     width: '360px',
//     height: '700px',
//     background: 'linear-gradient(#000000, #5D4241)',
//   }
// };

// const CharacterContainer = styled.div`
// position: relative;
// `;

// const layout = {
//   width: '328px',
//   height: '49px',
//   borderRadius: '4px',
//   // backgroundColor: '#584242',
//   position: 'relative',
//   top: '220px',
//   left: '15px',
//   // display: 'flex',
//   // justifyContent: 'space-between',
  
// };

// const buttonContainer = {
//   container: {
//     marginTop: '190px',
//     marginLeft: '37px',
//     gap: '8px',
//     width: '328px',
//     height: '48px',
//   },
//   backButton: {
//     width: '160px',
//     height: '48px',
//     borderRadius: '24px',
//     border: '1px solid #FFFFFF',
//     padding: '12px 8px 12px 8px',
//     backgroundColor: '#0000004D',
//     color: '#FFFFFF',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '24px',
//     lineHeight: '24.29px',
//     zIndex: '2',
    
//   },
//   nextButton: {
//     width: '160px',
//     height: '48px',
//     borderRadius: '24px',
//     padding: '12px 8px 12px 8px',
//     backgroundColor: '#DD7C2B',
//     border: '1px outset #FABA4E',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '24px',
//     lineHeight: '24.29px',
//     zIndex: '2',
//   }
// }

// const style = {
//   numberOfPlayerHeader: {
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '30px',
//     color: 'white',
//     lineHeight: '50px',
//   }
// }