import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
  json: { intro_text : "this is intro text"} ,
  combatJson: {}
}

const jsonSlice = createSlice({
  name: 'json',
  initialState,
  reducers: {
    storeJson: (state, action) => {
      state.json = action.payload;
    },

    storeCombatJson: (state, action) => {
      state.combatJson = action.payload;
    },

  },
});


export const { storeJson, storeCombatJson} = jsonSlice.actions;
export default jsonSlice.reducer;