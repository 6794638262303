import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import AppButton from '../uiElements/AppButton';
import Option from '../uiElements/Option';
import { useDispatch } from "react-redux";
import { storeJson } from '../redux/jsonSlice';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LocationPageOverlay from '../uiElements/LocationPageOverlay';
import LocationPageDataContentBar from '../uiElements/LocationPageDataContentBar';

import FillBox from '../uiElements/FillBox';
import Button from 'react-bootstrap/Button';

const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;

function LocationPage() {
  const [jsonData, setJsonData] = useState(null);
  const jsonFromRedux = useSelector((state) => state.jsonStore.json);
  const dispatch = useDispatch();

  // const location = {
  //   "location": "supermarket",
  //   "user": "player1",
  //   "params": {}
  // }

  const qrCodeResult = useSelector((state) => state.qrcodeStore.qrCodeResult);
  const currentPlayer = useSelector((state) => state.playerStore.currentPlayer);

  // const exploreLocation = async (location) => {
  //   try {
  //     const location = {
  //       "location": qrCodeResult,
  //       "user": currentPlayer,
  //       "params": {} 
  //     };

  //     console.log("Json is: " + location.location)

  //     const response = await axios.post('https://vgvqf6srb1.execute-api.ap-east-1.amazonaws.com/dev/visitLocation',
  //       location
  //     );
  //     console.log(response.data)

  //     dispatch(storeJson(response.data));
  //     setJsonData(response.data);
  //     // console.log(jsonData)

  //   } catch (error) {
  //     console.error('Error visiting location:', error);
  //   }
  // };

  // useEffect(() => {
  //   exploreLocation();
  // }, []);

  // if (!jsonData) {
  //   return (
  //     <Container>
  //       <Row>
  //         <Col sm></Col>
  //         <Col sm>
  //           Loading...
  
  //         </Col>
  //         <Col sm></Col>
  //       </Row>
  //     </Container>
  //   );
  // }

  return (
    <Container>
      <Row>
        <Col sm></Col>
        <Col sm>
          <LocationPageOverlay />
          <LocationPageDataContentBar /> 

        </Col>
        <Col sm></Col>
      </Row>
    </Container>
  );
}

export default LocationPage;

