import React, { useState, useRef, useEffect } from 'react';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import '../../css/OswaldFont.css';

import { WOW } from 'wowjs';
import 'animate.css';

const IntroContent = ( { title, text }) => {
  const handleScroll = (event) => {
    // You can perform any custom scrolling logic here
    console.log('Scrolling...');
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    new SimpleBar(scrollRef.current);
    new WOW().init();
  }, []);

  const textStyle = {
    fontFamily: 'Oswald',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23.71px',
    color: '#F2F2F2',
  };

  return (
    <div style={IntroComponent}>
      <div>
        <div
          style={{
            height: '270px',
            marginBottom: '30px',
            position: 'relative',
            left:'5px',
            top:'100px',
          }}
          ref={scrollRef}
          onScroll={handleScroll}
        >

          <div style={textStyle} >
            <div><h1 className="wow animate__animated animate__fadeIn" data-wow-delay = "2s" data-wow-duration = "2s">{title}</h1></div>
            <div className="wow animate__animated animate__fadeIn" data-wow-delay = "3s" data-wow-duration = "4s">{text}</div>
        
          </div>
        </div>
        <style>{`
          .simplebar-scrollbar:before {
            background-color: #DD7C2B !important;
            width: 4px !important;
            border-radius: 13px !important;
          }

          .simplebar-scrollbar:before {
            height: 105px !important;
          }

        `}</style>
      </div>
    </div>
  );
};

const IntroComponent = {
  width: '370px',
  height: '200px',
  position: 'absolute',
  top: '230px',
};

export default IntroContent;






























