import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StrongZombieOverlay from '../uiElements/StrongZombieOverlay';
import StrongZombieContent from '../uiElements/StrongZombieContent';



const EnterValuePage = () => {
    return(
        <Container>
            <Row>
                <Col sm></Col>
                <Col sm>
                    <StrongZombieOverlay />
                    <StrongZombieContent />
                </Col>
                <Col sm></Col>
            </Row>
        </Container>
    );
};

export default EnterValuePage;

