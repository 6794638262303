import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NumberOfPlayerOverlay from '../uiElements/NumberOfPlayerOverlay';
import '../../css/opacityContainer.css';
import Button from 'react-bootstrap/Button';
import image from '../../images/character.png';

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react';
import { useState } from 'react';
import { startGame } from '../redux/gameSlice';
import { createPlayer, clearPlayers, removeCurrentPlayer } from '../redux/playersSlice';

import '../../css/style.css';
import AppButton from '../uiElements/AppButton';
import NumberOfPlayer from '../uiElements/NumberOfPlayer'
import playerIcon from '../../images/character.png';

import ButtonSound from '../../sounds/Start.wav';
import ChooseSound from '../../sounds/Character.wav';
//  import ChooseSound from '../../sounds/ChooseSound.mp3';

import { WOW } from 'wowjs';
import '../../css/animationEffect.css';

const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;



function NumberOfPlayerPage() {

  const sound1Path = '../../sounds/Start.wav';
const sound2Path = '../../sounds/ChooseSound.mp3';
  const dispatch = useDispatch();
  const [numberOfPlayers, setNumberOfPlayers] = useState(2);
  const backgroundColor = ['#00A3D7', '#D70000', '#41A901', '#D7C100']

  const handlePlayerSelection = (numPlayers) => {
    setNumberOfPlayers(numPlayers);
    playSound()
  };

  const handleStartGame = () => {
    handleClick()
    // Create an array to hold the player objects
    const players = [];
    // Loop through the number of players and create Player instances
    for (let i = 1; i <= numberOfPlayers; i++) {
      const player = {
        id: i,
        playerName: "",
        health: 100, // Assuming initial hp = 100
        bgColor: backgroundColor[i - 1]
      };
      players.push(player);
      console.log(numberOfPlayers)
    }
    // Dispatch the createPlayer action for each player
    players.forEach((player) => {
      dispatch(createPlayer(player));
    });
    dispatch(startGame(numberOfPlayers));
  };

  const handleClick = () => {
    // Play the sound
    const audio = new Audio(ButtonSound );
    audio.play();

  };

  const playSound = (sound) => {
    const audio = new Audio(ChooseSound);
    audio.play();
  }

  useEffect(() => {
    dispatch(clearPlayers());
    dispatch(removeCurrentPlayer());
  }, []);


  return (
    // <div className="App">
    //   <header className="App-header">
    //     <div className="deadzone-container">
    //       <div className="background-image">
    //         <TitleText top="5vw" fontSize="6vw">NUMBER OF PLAYERS</TitleText>

    //         <TitleText top="40vw" fontSize="3vw">
    //           <NumberOfPlayer playerIcon={playerIcon} playerNumber={2} handlePlayerSelection={handlePlayerSelection}/>
    //           <NumberOfPlayer playerIcon={playerIcon} playerNumber={3} handlePlayerSelection={handlePlayerSelection}/>
    //           <NumberOfPlayer playerIcon={playerIcon} playerNumber={4} handlePlayerSelection={handlePlayerSelection}/>

    //         </TitleText>

    //         <TitleText top="100vw" fontSize="3vw">
    //           <AppButton text="BACK" color="primary" to="/" handleStartGame = {null}/>
    //           <AppButton text="NEXT" color="primary" to="/PlayersName" handleStartGame = {handleStartGame}/>
    //         </TitleText>
    //       </div>
    //     </div>
    //   </header>
    // </div>

    <Container>
      <Row>
        <Col sm></Col>
        <Col sm>
          <NumberOfPlayerOverlay />
          <div className="container-overlay">
            <div className="d-flex flex-column align-items-center">
              <div className="mb-3">
                <div className="image-container" style={numberOfPlayers === 2 ? selectedStyle : imageContainerStyle}>
                  <span style={numberStyle}>2</span>
                  <img src={image} alt="Image 1" style={imageStyle} onClick={() => handlePlayerSelection(2)} />
                </div>
                <h2 style={playerNumber}>2 players</h2>
              </div>
              <div className="mb-3">
                <div className="image-container" style={numberOfPlayers === 3 ? selectedStyle : imageContainerStyle}>
                  <span style={numberStyle}>3</span>
                  <img src={image} alt="Image 2" style={imageStyle} onClick={() => handlePlayerSelection(3)} />
                </div>
                <h2 style={playerNumber} >3 players</h2>
              </div>
              <div className="mb-3">
                <div className="image-container" style={numberOfPlayers === 4 ? selectedStyle : imageContainerStyle}>
                  <span style={numberStyle}>4</span>
                  <img src={image} alt="Image 3" style={imageStyle} onClick={() => handlePlayerSelection(4)} />
                </div>
                <h2 style={playerNumber}>4 players</h2>
              </div>
            </div>
            <div style={buttonContainer.container} className="d-flex flex-row">
              <Link to="/"><Button style={buttonContainer.backButton} onClick={handleClick} className="hoverEnlarge"> BACK</Button> </Link>
              <Link to="/PlayersNamePage"><Button style={buttonContainer.nextButton} onClick={() => {
                handleStartGame();
             
              }} className="hoverEnlarge">NEXT</Button></Link>
            </div>
          </div>
        </Col>
        <Col sm></Col>
      </Row>
    </Container>



  );
}

export default NumberOfPlayerPage;


const imageContainerStyle = {
  width: '64px',
  height: '64px',
  borderRadius: '2.29px',
  border: '1.5px solid #828282',
  backgroundColor: '#3E3E3ECC',
  opacity: '0.8',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '25px', // Add 16px margin on all sides
  position: 'relative', // Add relative positioning for the container
  transition: 'background-color 0.3s', // Add a transition effect for smooth color change
  cursor: 'pointer', // Set the cursor to a pointer when hovering over the container

};

const selectedStyle = {
  ...imageContainerStyle,
  backgroundColor: '#FFA500',
};


const numberStyle = {
  position: 'absolute',
  left: '23px',
  color: '#fff',
  fontSize: '57.6px',
  fontWeight: '400',
  fontFamily: 'Karantina, system-ui',
  zIndex: '2',
  pointerEvents: 'none',
};

const imageStyle = {
  marginTop: '13px',
  width: '80px',
  height: '80px',
  opacity: '0.6',
  mixBlendMode: 'darken',
};

const playerNumber = {
  // width: '74px',
  // height: '32px',
  fontWeight: '400',
  fontFamily: 'Karantina, system-ui',
  fontSize: '32px',
  lineHeight: '10px',
  textAlign: 'center',
  color: '#BDBDBD',
}


const buttonContainer = {
  container: {
    marginTop: '70px',
    marginLeft: '50px',
    gap: '8px',
    width: '328px',
    height: '48px',
  },
  backButton: {
    width: '160px',
    height: '48px',
    borderRadius: '24px',
    border: '1px solid #FFFFFF',
    padding: '12px 8px 12px 8px',
    backgroundColor: '#0000004D',
    color: '#FFFFFF',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '24.29px',
    zIndex: '2',
    position: 'relative',
  },
  nextButton: {
    width: '160px',
    height: '48px',
    borderRadius: '24px',
    padding: '12px 8px 12px 8px',
    backgroundColor: '#DD7C2B',
    border: '1px outset #FABA4E',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '24.29px',
    zIndex: '2',
    position: 'absolute',
  }
}