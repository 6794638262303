import React from 'react';
import Icon from '../../images/character.png';


const CharacterIcon = ({ numberOfPlayer, backgroundColor }) => {

    const playerFourContainer = {
        width: '48px',
        height: '48px',
        borderRadius: '4px',
        backgroundColor:  backgroundColor,

        
    }

    console.log(backgroundColor)
    console.log(playerFourContainer.backgroundColor)
    return (
        <div style={playerFourContainer}>
            <span style={PlayerNumber}>{numberOfPlayer}</span>
            <img src={Icon} style={playerFour} />
           
        </div>
    ) 
}

export default CharacterIcon




const playerFour = {
    position: 'absolute',
    left: '-6px',
    width: '60px',
    height: '60px',
    opacity: '0.6',
    mixBlendMode: 'darken',
    zIndex: '1',
}

const PlayerNumber = {
    width: '10px',
    height: '49px',
    position: 'absolute',
    top: '-10px',
    left: '20px',
    color: '#FFFFFF',
    fontSize: '45px',
    fontWeight: '400',
    fontFamily: 'Karantina, system-ui',
    zIndex: '2',
  };
