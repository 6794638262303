import React from 'react';

const ContinueCampaignOverlay = () => {
    return (
        <div style={styles.square}>
            <h1 style={style.numberOfPlayerHeader}>CONTINUE CAMPAIGN</h1>
        </div>
    )
};

export default ContinueCampaignOverlay;

const styles = {
    square: {
        width: '25rem', // 400px / 16px = 25rem
        height: '9.375rem', // 150px / 16px = 9.375rem
        padding: '1.25rem', // 20px / 16px = 1.25rem
        textAlign: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#2D0B0A',
    }
}

const style = {
    numberOfPlayerHeader: {
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '3rem', // 48px / 16px = 3rem
        color: 'white',
        lineHeight: '3.75rem', // 60px / 16px = 3.75rem
        position: 'relative',
        top: '1.25rem', // 20px / 16px = 1.25rem
    }
}



//origin version

// import React from 'react';

// const ContinueCampaignOverlay = () => {
//     return (
//         <div style={styles.square}>
//             <h1 style={style.numberOfPlayerHeader}>CONTINUE CAMPAING</h1>
//         </div>
//     )
//   };

//   export default ContinueCampaignOverlay;

//   const styles = {
//     square: {
//     width: '400px',
//     height: '150px',
//     padding: '20px',
//     textAlign: 'center',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     backgroundColor: '#2D0B0A',
//   }
// }

// const style = {
//     numberOfPlayerHeader:{
//       fontFamily: 'Karantina, system-ui',
//       fontWeight: '400',
//       fontSize: '48px',
//       color: 'white',
//       lineHeight: '60px',
//       position:'relative',
//       top:'20px',
//     }
//   }