
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/OswaldFont.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import fuel from '../../images/fuel.png';
import sound from '../../images/noise.png';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Option from '../uiElements/Option';
import { useDispatch } from "react-redux";
import { storeJson } from '../redux/jsonSlice';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


import { WOW } from 'wowjs';
import 'animate.css';

const LocationPageDataComponent = () => {

    const jsonFromRedux = useSelector((state) => state.jsonStore.json);

    useEffect(() => {
        new WOW().init();
      }, []);
    return (
        <div>
            {[...Array(jsonFromRedux.result.options.length)].map((_, index) => (
                <div key={index} className={`wow animate__animated animate__fadeIn`} data-wow-delay = "1.5s">
                <Option key={index} index={index} />
                </div>
            ))}
        </div>
    );
};

export default LocationPageDataComponent;



