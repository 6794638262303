import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AttackResultPageOverlay from '../uiElements/AttackResultPageOverlay';
import AttackResultPageContentBar from '../uiElements/AttackResultPageContentBar';

const AttackResultPage = () => {
    return(
        <Container>
            <Row>
                <Col sm></Col>
                <Col sm>
                    <AttackResultPageOverlay />
                    <AttackResultPageContentBar />
                </Col>
                <Col sm></Col>
            </Row>
        </Container>
    );
};

export default AttackResultPage;