import React, { useState, useRef, useEffect } from 'react';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import '../../css/OswaldFont.css';
import LocationPageDataComponent from './LocationPageDataComponent';
import { useSelector } from 'react-redux';

import { WOW } from 'wowjs';
import 'animate.css';

const LocationPageDataContent = () => {
  const handleScroll = (event) => {
    // You can perform any custom scrolling logic here
    console.log('Scrolling...');
  };
  const jsonFromRedux = useSelector((state) => state.jsonStore.json);

  const scrollRef = useRef(null);

  useEffect(() => {
    new SimpleBar(scrollRef.current);
    new WOW().init();
  }, []);

  const textStyle = {
    fontFamily: 'Oswald',
    fontWeight: 400,
    fontSize: '1rem', // 16px / 16px = 1rem
    lineHeight: '1.481rem', // 23.71px / 16px = 1.481rem
    color: '#F2F2F2',
  };

  return (
    <div style={DataComponent}>
      <div>
        <div
          style={{
            height: '16.875rem', // 270px / 16px = 16.875rem
            marginBottom: '1.25rem', // 20px / 16px = 1.25rem
            position: 'relative',
          }}
          ref={scrollRef}
          onScroll={handleScroll}
        >
          <div style={textStyle}  className="wow animate__animated animate__fadeIn" data-wow-delay = "0.8s">
            {jsonFromRedux.result.intro_text}
          </div>
          
          <br />
          <LocationPageDataComponent />

        </div>
        <style>{`
          .simplebar-scrollbar:before {
            background-color: #DD7C2B !important;
            width: 0.25rem !important; // 4px / 16px = 0.25rem
            border-radius: 0.8125rem !important; // 13px / 16px = 0.8125rem
          }
        `}</style>
      </div>
    </div>
  );
};

const DataComponent = {
  width: '23.125rem', // 370px / 16px = 23.125rem
  height: '12.5rem', // 200px / 16px = 12.5rem
  position: 'relative',
  top: '-2.8125rem', // -45px / 16px = -2.8125rem
  left: '0.3125rem', // 5px / 16px = 0.3125rem
};

export default LocationPageDataContent;



//origin version

// import React, { useState, useRef, useEffect } from 'react';
// import SimpleBar from 'simplebar';
// import 'simplebar/dist/simplebar.min.css';
// import '../../css/OswaldFont.css';
// import LocationPageDataComponent from './LocationPageDataComponent';
// import { useSelector } from 'react-redux';

// const LocationPageDataContent = () => {
//   const handleScroll = (event) => {
//     // You can perform any custom scrolling logic here
//     console.log('Scrolling...');
//   };
//   const jsonFromRedux = useSelector((state) => state.jsonStore.json);

//   const scrollRef = useRef(null);

//   useEffect(() => {
//     new SimpleBar(scrollRef.current);
//   }, []);

//   const textStyle = {
//     fontFamily: 'Oswald',
//     fontWeight: 400,
//     fontSize: '16px',
//     lineHeight: '23.71px',
//     color: '#F2F2F2',
//   };

//   return (
//     <div style={DataComponent}>
//       <div>
//         <div
//           style={{
//             height: '270px',
//             marginBottom: '20px',
//             position: 'relative',
//           }}
//           ref={scrollRef}
//           onScroll={handleScroll}
//         >
//           <div style={textStyle}>
//           {jsonFromRedux.result.intro_text}

//           </div>
          
//           <br />
//           <LocationPageDataComponent />

//         </div>
//         <style>{`
//           .simplebar-scrollbar:before {
//             background-color: #DD7C2B !important;
//             width: 4px !important;
//             border-radius: 13px !important;
//           }


//         `}</style>
//       </div>
//     </div>
//   );
// };

// const DataComponent = {
//   width: '370px',
//   height: '200px',
//   position: 'relative',
//   top: '-45px',
//   left: '5px',
// };

// export default LocationPageDataContent;
