import React from 'react';
import '../../css/hamburgerMenu.css';
import '../../css/animationEffect.css';



const HamburgerMenu = ({ isMenuOpen, toggleMenu }) => {
  // useEffect(() => {
  //   new WOW().init();
  // }, []);
  return (
    <div>
      <div className="hamburger1 hoverEnlarge" onClick={toggleMenu} >
        <div className={`top ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`meat ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`bottom ${isMenuOpen ? 'open' : ''}`}></div>
      </div>
    </div>

  );
};

export default HamburgerMenu;



//origin version

// import React, { useState } from 'react';
// import '../css/hamburgerMenu.css';

// const HamburgerMenu = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className="hamburger1" onClick={toggleMenu}>
//       <div className={`top ${isOpen ? 'open' : ''}`}></div>
//       <div className={`meat ${isOpen ? 'open' : ''}`}></div>
//       <div className={`bottom ${isOpen ? 'open' : ''}`}></div>

//       {isOpen && (
//         <nav className="menu1">
//           <a className="link1" href="">
//             Our Models
//           </a>
//           <a className="link1" href="">
//             Specialties
//           </a>
//           <a className="link1" href="">
//             About
//           </a>
//           <a className="link1" href="">
//             Blog
//           </a>
//           <a className="link1 kbutton-nav" href="">
//             Contact
//           </a>
//         </nav>
//       )}
//     </div>
//   );
// };

// export default HamburgerMenu;



