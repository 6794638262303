import React from 'react';
import LocationPageDataContent from './LocationPageDataContent';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import ButtonSound from '../../sounds/Start.wav';
import '../../css/animationEffect.css';

const LocationPageDataContentBar = () => {
    const playSound = () => {
        const audio = new Audio(ButtonSound);
        audio.play();
      }
    return(
        <div style={ContentComponent}>

            <div class="container">
                <div class="row">
                    <div class="col">
                    <LocationPageDataContent />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                    <Link to="/ScanLocationPage"  className="hoverEnlarge" >
                    <Button style={buttonContainer.backButton} onClick={playSound}>BACK</Button>
                    </Link>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default LocationPageDataContentBar;


const ContentComponent = {
    width: '25rem', // 400px / 16px = 25rem
    height: '28.4375rem', 
    background: 'linear-gradient(#000000, #5D4241)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem', // 32px / 16px = 2rem
  };
  
  const buttonContainer = {
    backButton: {
      width: '20.5rem', // 328px / 16px = 20.5rem
      height: '3rem', // 48px / 16px = 3rem
      borderRadius: '1.5rem', // 24px / 16px = 1.5rem
      border: '1px solid',
      padding: '0.75rem 0.5rem', // 12px / 16px = 0.75rem, 8px / 16px = 0.5rem
      backgroundColor: '#0000004D',
      fontFamily: 'Karantina, system-ui',
      fontWeight: '400',
      fontSize: '1.5rem', // 24px / 16px = 1.5rem
      lineHeight: '1.517rem', // 24.29px / 16px = 1.517rem
      border: '1px outset #FFFFFF',
      zIndex: '2',
      position: 'relative',
      top: '3.125rem', // 50px / 16px = 3.125rem
      left: '1.25rem', // 20px / 16px = 1.25rem
    },
  };

//origin version

// const ContentComponent = {
//     width: '400px',
//     height: '365px',
//     background: 'linear-gradient(#000000, #5D4241)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '32px',
//   };

//   const buttonContainer ={
//     backButton: {
//     width: '328px',
//     height: '48px',
//     borderRadius: '24px',
//     border: '1px solid',
//     padding: '12px 8px',
//     backgroundColor: '#0000004D',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '24px',
//     lineHeight: '24.29px',
//     border: '1px outset #FFFFFF',
//     zIndex: '2',
//     position:'relative',
//     top: '50px',
//     left:'20px',
//     },
//   };

