import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/opacityContainer.css';
import Button from 'react-bootstrap/Button';
import ContinueCampaignOverlay from '../uiElements/ContinueCampaignOverlay';
import ContinueCampaignContent from '../uiElements/ContinueCampaignContent';
import { Link } from 'react-router-dom';

import ButtonSound from '../../sounds/Start.wav';
import '../../css/animationEffect.css';

const ContinueCampaignPage = () => {
    const playSound = () => {
        const audio = new Audio(ButtonSound);
        audio.play();
      }
    return(
        <Container>
            <Row>
                <Col sm></Col>
                <Col sm>
                    <ContinueCampaignOverlay />
                    <div className="container-overlay">

                    <div className="container">
                        <div className="row">
                            <div className="col">
                            <ContinueCampaignContent />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                            <Link to = "/"> <Button style={buttonStyle} className="hoverEnlarge" onClick={playSound}>BACK</Button></Link>
                            </div>
                        </div>
                    </div>

                        
                    </div>
                </Col>
                <Col sm></Col>
            </Row>
        </Container>
    );
};

export default ContinueCampaignPage;

const buttonStyle = {
    width: '20.5rem', // 328px / 16px = 20.5rem
    height: '3rem', // 48px / 16px = 3rem
    borderRadius: '1.5rem', // 24px / 16px = 1.5rem
    border: '1px solid #FFFFFF',
    padding: '0.75rem 0.5rem', // 12px / 16px = 0.75rem, 8px / 16px = 0.5rem
    backgroundColor: '#0000004D',
    fontFamily: 'Karantina',
    fontWeight: 400,
    fontSize: '1.5rem', // 24px / 16px = 1.5rem
    lineHeight: '1.518rem', // 24.29px / 16px = 1.518rem
    color: '#FFFFFF',
    position: 'relative',
    top:'20rem', // 320px / 16px = 20rem
    left:'1.563rem', // 25px / 16px = 1.563rem
}



//origin version 

// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import '../../css/opacityContainer.css';
// import Button from 'react-bootstrap/Button';
// import ContinueCampaignOverlay from '../uiElements/ContinueCampaignOverlay';
// import ContinueCampaignContent from '../uiElements/ContinueCampaignContent';
// import { Link } from 'react-router-dom';

// const ContinueCampaignPage = () => {
//     return(
//         <Container>
//             <Row>
//                 <Col sm></Col>
//                 <Col sm>
//                     <ContinueCampaignOverlay />
//                     <div className="container-overlay">

//                     <div class="container">
//                         <div class="row">
//                             <div class="col">
//                             <ContinueCampaignContent />
//                             </div>
//                         </div>
//                         <div class="row">
//                             <div class="col">
//                             <Link to = "/"> <Button style={buttonStyle}>BACK</Button></Link>
//                             </div>
//                         </div>
//                     </div>

                        
//                     </div>
//                 </Col>
//                 <Col sm></Col>
//             </Row>
//         </Container>
//     );
// };

// export default ContinueCampaignPage;


// const buttonStyle = {
//     width: '328px',
//     height: '48px',
//     borderRadius: '24px',
//     border: '1px solid #FFFFFF',
//     padding: '12px 8px',
//     backgroundColor: '#0000004D',
//     fontFamily: 'Karantina',
//     fontWeight: 400,
//     fontSize: '24px',
//     lineHeight: '24.29px',
//     color: '#FFFFFF',
//     position: 'relative',
//     top:'320px',
//     left:'25px',
// }