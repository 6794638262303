import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NumberOfPlayerOverlay from '../uiElements/NumberOfPlayerOverlay';
import '../../css/opacityContainer.css';
import Button from 'react-bootstrap/Button';
import Overlay from '../uiElements/Overlay';
import backgroundImage from '../../images/background.png'; 
import image from '../../images/character.png';

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';
import { startGame } from '../redux/gameSlice';

import '../../css/style.css';
import ButtonSound from '../../sounds/Start.wav';
import backgroundSound from '../../sounds/BGSound.mp3';

import { WOW } from 'wowjs';
import 'animate.css';
import '../../css/animationEffect.css';

const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;

const CoverPage = () => {
  // const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    // Play the sound
    const audio = new Audio(ButtonSound);
    audio.play();

    // setClicked(!clicked);
  };

  useEffect(() => {
    new WOW().init();
  }, []);

  // useEffect(() => {
  //   const playAudio = () => {
  //     const audio = new Audio(backgroundSound);
  //     audio.loop = true;
  //     audio.play();
  //   };

  //   const handleInteraction = () => {
  //     document.removeEventListener('mouseover', handleInteraction);
  //     document.removeEventListener('click', handleInteraction);
  //     playAudio();
  //   };

  //   document.addEventListener('mouseover', handleInteraction);
  //   document.addEventListener('click', handleInteraction);

  //   return () => {
  //     document.removeEventListener('mouseover', handleInteraction);
  //     document.removeEventListener('click', handleInteraction);
  //   };
  // }, []);

  return (
    // <div className="App">
    //   <BackgroundImageContainer />
    //   <BgText mainText={true} />
    //   <TitleText top="18vw" fontSize="3vw">SETTLEMENTS</TitleText>
    //   <TitleText top="50vw" fontSize="3vw">
    //     <AppButton text="NEW CAMPAIGN" color="primary" to="/NumberOfPlayersPage" />
    //     <AppButton text="CONTINUE CAMPAIGN" color="primary" to="/ContinueCampaignPage" />
    //   </TitleText>
    // </div>
    

    <Container>
      <Row>
        <Col sm> </Col>
        <Col sm>
          <Overlay />
          <div style={styles.container}>
            <div style={styles.buttonContainer} className="d-flex flex-column">
              <Link to="/NumberOfPlayersPage">
                <Button className="wow animate__animated animate__fadeIn hoverEnlarge"  data-wow-delay = "1s" style={styles.newCampaignButton} onClick={handleClick}>NEW CAMPAIGN</Button>
              </Link>
              <Link to="/ContinueCampaignPage">
              <Button className={`wow animate__animated animate__fadeIn hoverEnlarge`} data-wow-delay = "1s" style={styles.continueCampaignButton} onClick={handleClick}>CONTINUE CAMPAIGN</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col sm></Col>
      </Row>
    </Container>
  );
}

const styles = {
  container: {
    width: '25rem',
    height: '32.9375rem',
    padding: '1.25rem',
    textAlign: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#f0f0f0',
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '0.625rem',
  },
  subtitle: {
    fontSize: '1.125rem',
  },
  buttonContainer: {
    marginTop: '23.7rem',
    marginLeft: '0.9375rem',
  },
  newCampaignButton: {
    width: '20.5rem',
    height: '3rem',
    borderRadius: '1.5rem',
    border: '0.0625rem solid',
    padding: '0.75rem 0.5rem',
    marginBottom: '0.5rem',
    backgroundColor: '#DD7C2B',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '1.517rem',
    border: '0.0625rem outset #FABA4E',
  },
  continueCampaignButton: {
    width: '20.5rem',
    height: '3rem',
    borderRadius: '1.5rem',
    border: '0.0625rem solid #CECECE',
    padding: '0.75rem 0.5rem',
    marginBottom: '0.5rem',
    backgroundColor: '#FFFFFF',
    color: '#4F4F4F',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '1.517rem',
  }
};

// Media Queries
const mediaQueries = {
  small: `@media (max-width: 320px)`, // iPhone SE
  medium: `@media (max-width: 414px)`, // iPhone XR
};

// Adjust styles for iPhone SE
styles.container = {
  ...styles.container,
  [mediaQueries.small]: {
    width: '20rem',
    height: '29.125rem',
    padding: '1rem',
    marginTop: '4rem',
  },
  [mediaQueries.medium]: {
    ...styles.container,
  },
};

styles.buttonContainer = {
  ...styles.buttonContainer,
  [mediaQueries.small]: {
    marginTop: '22.875rem',
    marginLeft: '0.5rem',
  },
  [mediaQueries.medium]: {
    ...styles.buttonContainer,
  },
};

styles.newCampaignButton = {
  ...styles.newCampaignButton,
  [mediaQueries.small]: {
    width: '18rem',
    height: '2.5rem',
    marginBottom: '0.25rem',
    fontSize: '1.25rem',
  },
  [mediaQueries.medium]: {
    ...styles.newCampaignButton,
  },
};

styles.continueCampaignButton = {
  ...styles.continueCampaignButton,
  [mediaQueries.small]: {
    width: '18rem',
    height: '2.5rem',
    marginBottom: '0.25rem',
    fontSize: '1.25rem',
  },
  [mediaQueries.medium]: {
    ...styles.continueCampaignButton,
  },
};

export default CoverPage;




