import React from 'react';

const PlayerNamesOverlay = () => {
  return (
    <div style={styles.square}>
      <h1 style={style.numberOfPlayerHeader}>PLAYERS NAMES</h1>
    </div>
  );
};

export default PlayerNamesOverlay;

const styles = {
  square: {
    width: '25rem',
    height: '9.375rem',
    padding: '1.25rem',
    textAlign: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#2D0B0A',
  },
};

const style = {
  numberOfPlayerHeader: {
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '3rem',
    color: 'white',
    lineHeight: '3.75rem',
  },
};




//origin version


// import React from 'react';

// const PlayerNamesOverlay = () => {
//     return (
//         <div style={styles.square}>
//             <h1 style={style.numberOfPlayerHeader}>PLAYERS NAMES</h1>
//         </div>
//     )
//   };

//   export default PlayerNamesOverlay;

//   const styles = {
//     square: {
//     width: '400px',
//     height: '150px',
//     padding: '20px',
//     textAlign: 'center',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     backgroundColor: '#2D0B0A',
//   }
// }

// const style = {
//     numberOfPlayerHeader:{
//       fontFamily: 'Karantina, system-ui',
//       fontWeight: '400',
//       fontSize: '48px',
//       color: 'white',
//       lineHeight: '60px',
//     }
//   }