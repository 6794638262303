import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  qrCodeResult: null,
};

const qrCodeSlice = createSlice({
  name: 'qrCode',
  initialState,
  reducers: {
    setQrCodeResult: (state, action) => {
      state.qrCodeResult = action.payload;
    },

    removeQrCodeResult: (state) => {
        state.qrCodeResult = null;
      },
  },
});

export const { setQrCodeResult,removeQrCodeResult } = qrCodeSlice.actions;
export default qrCodeSlice.reducer;