import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CharacterIcon from '../uiElements/CharacterIcon';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

import ButtonSound from '../../sounds/Start.wav';

import { useSelector, useDispatch } from 'react-redux';



const Properties = () => {
    const currentPlayer = useSelector((state) => state.playerStore.currentPlayer);
    const playerName = useSelector((state) => state.playerStore.currentPlayer.playerName);
    const backgroundColor = useSelector((state) => state.playerStore.currentPlayer.bgColor);
    const playerID = useSelector((state) => state.playerStore.currentPlayer.id);
    const playSound = () => {
        const audio = new Audio(ButtonSound);
        audio.play();
      }
    return (
        <Container>
            <Row>
                <Col sm></Col>
                <Col sm>
                    <div style={styles.container}>
                        <div style={scanContainer}>
                            <p style={textStyle}>
                                {Object.entries(currentPlayer).map(([key, value]) => {
                                    if (key !== 'id' && key !== 'playerName' && key !== 'bgColor') {
                                        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                                        return (
                                            <div key={key}>
                                                {capitalizedKey}: {value}
                                            </div>
                                        );
                                    }
                                    return null; // Skip rendering for excluded keys
                                })}
                            </p>
                        </div>
                        <div style={layout}>
                            <div class="row">
                                <div class="col-3">
                                    <CharacterIcon numberOfPlayer={playerID} backgroundColor={backgroundColor} />
                                </div>
                                <div class="col-9">
                                    <span style={style.numberOfPlayerHeader}>{playerName}</span>
                                </div>
                                <div class="col">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                </div>
                                <div class="col-3">
                                    <br></br>
                                    <Link to="/MainPage">
                                        <Button style={buttonContainer.backButton} className="hoverEnlarge" onClick={playSound}>BACK</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>

                </Col>
                <Col sm></Col>
            </Row>
        </Container>
    );
};

const styles = {
    container: {
        width: '360px',
        height: '700px',
        background: 'linear-gradient(#000000, #5D4241)',
    }
};

const CharacterContainer = styled.div`
  position: relative;
`;

const layout = {
    width: '328px',
    height: '49px',
    borderRadius: '4px',
    // backgroundColor: '#584242',
    position: 'relative',
    top: '50px',
    left: '15px',
    // display: 'flex',
    // justifyContent: 'space-between',
};

export default Properties;

const scanContainer = {
    width: '328px',
    height: '450px',
    borderRadius: '8px',
    border: '1px solid #F2994A',
    padding: '0px 16px',
    backgroundColor: '#FFFFFF33',
    position: 'relative',
    top: '40px',
    left: '17px',
    marginBottom: '50px'
};

const button = {
    scanLocation: {
        width: '296px',
        height: '48px',
        borderRadius: '24px',
        border: '1px solid',
        padding: '12px 8px',
        marginBottom: '8px',
        backgroundColor: '#DD7C2B',
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '24.29px',
        border: '1px outset #FABA4E',
        position: 'relative',
        top: '120px',
    },
    icon: {
        width: '24px',
        height: '24px',
        position: 'relative',
        right: '12px',
    }
};

const buttonContainer = {
    container: {
        marginTop: '190px',
        marginLeft: '37px',
        gap: '8px',
        width: '328px',
        height: '48px',
    },
    backButton: {
        width: '160px',
        height: '48px',
        borderRadius: '24px',
        border: '1px solid #FFFFFF',
        padding: '12px 8px 12px 8px',
        backgroundColor: '#0000004D',
        color: '#FFFFFF',
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '24.29px',
        zIndex: '2',

    },
    nextButton: {
        width: '160px',
        height: '48px',
        borderRadius: '24px',
        padding: '12px 8px 12px 8px',
        backgroundColor: '#DD7C2B',
        border: '1px outset #FABA4E',
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '24.29px',
        zIndex: '2',
    }
}

const style = {
    numberOfPlayerHeader: {
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '30px',
        color: 'white',
        lineHeight: '50px',
    }
}

const textStyle = {
    fontFamily: 'Oswald',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px', // Increase the line-height value to add more space between lines
    color: '#F2F2F2',
    marginTop: '10px', // Add more margin on top by increasing the marginTop value
};