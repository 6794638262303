import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';

import { WOW } from 'wowjs';
import 'animate.css';
import ButtonSound from '../../sounds/Start.wav';

const MainPageOverlay = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const playSound = () => {
    const audio = new Audio(ButtonSound);
    audio.play();
  }

  useEffect(() => {
    new WOW().init();
  }, []);

  return (
    <div style={styles.container} >
      <h1 style={styles.numberOfPlayerHeader} className="wow animate__animated animate__slideInDown">ENCOUNTERS</h1>
      <div style={styles.hamburgerContainer}>
        <Link to="/MenuPage" onClick={playSound} >
          <HamburgerMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '25rem',
    // margin: '0 auto',
    backgroundColor: '#2D0B0A',
    padding: '1.25rem',
  },
  numberOfPlayerHeader: {
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '3rem',
    color: 'white',
    lineHeight: '3.75rem',
    margin: 0,
  },
  hamburgerContainer: {
    position: 'absolute',
    marginTop: '-3.75rem',
    marginLeft: '18.125rem', // Adjust the margin as needed
  },
};

export default MainPageOverlay;



//origin version

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import HamburgerMenu from './HamburgerMenu';

// const MainPageOverlay = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.numberOfPlayerHeader}>ENCOUNTERS</h1>
//       <div style={styles.hamburgerContainer}>
//         <Link to="/MenuPage"> 
//           <HamburgerMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
//         </Link>
//       </div>
//     </div>
//   );
// };

// const styles = {
//     container: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       width: '400px',
//       // margin: '0 auto',
//       backgroundColor: '#2D0B0A',
//       padding: '20px',
//     },
//     numberOfPlayerHeader: {
//       fontFamily: 'Karantina, system-ui',
//       fontWeight: '400',
//       fontSize: '48px',
//       color: 'white',
//       lineHeight: '60px',
//       margin: 0,
//     },
//     hamburgerContainer: {
//       position: 'absolute',
//       marginTop: '-60px',
//       marginLeft: '290px', // Adjust the margin as needed
//     },
//   };

// export default MainPageOverlay;




