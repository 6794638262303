import React ,{useEffect}from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/OswaldFont.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import star from '../../images/star.png';

import { WOW } from 'wowjs';
import 'animate.css';
import ButtonSound from '../../sounds/Start.wav';
import '../../css/animationEffect.css';
const AttackResultPageComponent = () => {
    useEffect(() => {
        new WOW().init();
      }, []);
    return(
        <div>

            <div style={component.container}>
                <Container  className={`wow animate__animated animate__fadeIn`} data-wow-delay = "0.5s">
                    <Row>
                        <Col>
                        <p style={component.heading}>Encounter result</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <hr style={component.break} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                <img src={star} alt="star" style={component.star}/>
                                </div>
                                <div class="col">
                                    <p style={component.text}>+2 Victory Points</p>
                                </div>
                            </div>
                        </div>

                        </Col>
                    </Row>
                </Container>
            </div>
            <br />
            <br />

        </div>
    );
};

export default AttackResultPageComponent;

const component={
    container:{
        width: '328px',
        height: '127px',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF33',
        border: '1px solid #F2994A',
        padding: '16px',
        gap: '16px',
        position:'relative',
        top:'30px',
        left:'20px',
    },
    heading:{
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '32.38px',
        textAlign: 'center',
        color:'#FFFFFF',
    },
    break:{
        width: '296px',
        borderWidth: '2px',
        borderColor: '#FFFFFF4D',
        position:'relative',
        top:'-20px',
        right:'7px',
    },
    star:{
        width:'25px',
        height:'25px',
        position:'relative',
        top:'-20px',
        left:'50px',
    },
    text:{
        fontFamily: 'Oswald',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '29.64px',
        textAlign: 'center',
        color:'#F2F2F2',
        position:'relative',
        top:'-20px',
        right:'35px',
        width:'133px',
    }
}

