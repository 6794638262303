import React from 'react';
import IntroContent from './IntroContent';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import ButtonSound from '../../sounds/Start.wav';
import '../../css/animationEffect.css';

const baseFontSize = 16; // Set your base font size here

const IntroContentBar = ({ title, text }) => {

  const playSound = (sound) => {
    const audio = new Audio(ButtonSound);
    audio.play();
  }


  return (
    <div style={ContentComponent}>
      <IntroContent title={title} text={text} />
      <Link to="/MainPage">
        <Button style={buttonContainer.startGameButton} className="hoverEnlarge" onClick={playSound}>START GAME</Button>
      </Link>
    </div>
  );
};

export default IntroContentBar;

const ContentComponent = {
  width: '25rem', // 400px / baseFontSize
  height: '27.1875rem',
  background: 'linear-gradient(#000000, #5D4241)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2rem', // 32px / baseFontSize
};

const buttonContainer = {
  startGameButton: {
    width: '20.5rem', // 328px / baseFontSize
    height: '3rem', // 48px / baseFontSize
    borderRadius: '1.5rem', // 24px / baseFontSize
    border: '0.0625rem solid', // 1px / baseFontSize
    padding: '0.75rem 0.5rem', // 12px 8px / baseFontSize
    backgroundColor: '#DD7C2B',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.5rem', // 24px / baseFontSize
    lineHeight: '1.518rem', // 24.29px / baseFontSize
    border: '0.0625rem outset #FABA4E', // 1px outset #FABA4E / baseFontSize
    zIndex: '2',
    marginTop: '20rem', // 320px / baseFontSize
  },
};



//origin version

// import React from 'react';
// import IntroContent from './IntroContent';
// import Button from 'react-bootstrap/Button';
// import { Link } from 'react-router-dom';


// const IntroContentBar = ( { title, text }) => {
//     return(
//         <div style={ContentComponent}>
//             <IntroContent title={title} text={text}/>
//             <Link to="/MainPage"><Button style={buttonContainer.startGameButton}>START GAME</Button></Link>
//         </div>
//     );
// };

// export default IntroContentBar;

// const ContentComponent = {
//     width: '400px',
//     height: '400px',
//     background: 'linear-gradient(#000000, #5D4241)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '32px',
//   };

//   const buttonContainer ={
//     startGameButton: {
//     width: '328px',
//     height: '48px',
//     borderRadius: '24px',
//     border: '1px solid',
//     padding: '12px 8px',
//     backgroundColor: '#DD7C2B',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '24px',
//     lineHeight: '24.29px',
//     border: '1px outset #FABA4E',
//     zIndex: '2',
//     marginTop: '320px',
//     },
//   };