import React, { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { setQrCodeResult } from '../redux/qrcodeSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { storeJson } from '../redux/jsonSlice';

import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

import { WOW } from 'wowjs';
import 'animate.css';
import ScanSound from '../../sounds/Scan.wav';

const ViewFinder = () => {
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState(null);
  const [showScanner, setShowScanner] = useState(false);
  const qrCodeResult = useSelector((state) => state.qrcodeStore.qrCodeResult);
  const currentPlayer = useSelector((state) => state.playerStore.currentPlayer);
  const dispatch = useDispatch();

  const playSound = () => {
    const audio = new Audio(ScanSound);
    audio.play();
  }

  const handleDecode = (result) => {

    dispatch(setQrCodeResult(result));
    console.log(result); // You can remove this console.log if not needed

    const exploreLocation = async (location) => {
      try {
        const location = {
          "location": qrCodeResult,
          "user": currentPlayer,
          "params": {}
        };

        console.log("Json is: " + location.location)

        const response = await axios.post('https://vgvqf6srb1.execute-api.ap-east-1.amazonaws.com/dev/visitLocation',
          location
        );
        console.log(response.data)

        dispatch(storeJson(response.data));
        setJsonData(response.data);
        // console.log(jsonData)
        if (response.data.result) {
          navigate('/LocationPage'); // Programmatically navigate to "/LocationPage"
          playSound();
        } else {
          console.log("No data")
        }


      } catch (error) {
        console.error('Error visiting location:', error);
      }
    };


    exploreLocation()



  };

  useEffect(() => {
    new WOW().init();
  }, []);


  return (
    <div
      style={scanContainer}
    >
      <div className={`wow animate__animated animate__bounce hoverEnlarge`} data-wow-delay = "0.5s">
      {!showScanner && (
        <Button style={button.scanLocation} onClick={() => setShowScanner(true)}>
          <FontAwesomeIcon icon={faCamera} style={button.icon} ></FontAwesomeIcon > Scan Location
        </Button>
      )}</div>

      {showScanner && (
        <QrScanner
          onDecode={handleDecode}
          onError={(error) => console.log(error?.message)}

        />
      )}

    </div>
  );
};

export default ViewFinder;

const baseFontSize = 16; // Set the base font size in pixels

const scanContainer = {
  width: '20.5rem', // Convert pixel value to rem: 328px / 16px = 20.5rem
  height: '20.5rem', // Convert pixel value to rem: 328px / 16px = 20.5rem
  borderRadius: '0.5rem', // Convert pixel value to rem: 8px / 16px = 0.5rem
  border: '0.0625rem solid #F2994A', // Convert pixel value to rem: 1px / 16px = 0.0625rem
  padding: '1rem', // Convert pixel value to rem: 16px / 16px = 1rem
  backgroundColor: '#FFFFFF33',
  position: 'relative',
  top: '1.875rem', // Convert pixel value to rem: 30px / 16px = 1.875rem
  left: '2.3rem', // Convert pixel value to rem: 17px / 16px = 1.0625rem
};

const button = {
  scanLocation: {
    width: '18.5rem', // Convert pixel value to rem: 296px / 16px = 18.5rem
    height: '3rem', // Convert pixel value to rem: 48px / 16px = 3rem
    borderRadius: '1.5rem', // Convert pixel value to rem: 24px / 16px = 1.5rem
    border: '0.0625rem solid', // Convert pixel value to rem: 1px / 16px = 0.0625rem
    padding: '0.75rem 0.5rem', // Convert pixel value torem: 12px / 16px = 0.75rem
    marginBottom: '0.5rem', // Convert pixel value to rem: 8px / 16px = 0.5rem
    backgroundColor: '#DD7C2B',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '1.5rem', // Convert pixel value to rem: 24px / 16px = 1.5rem
    lineHeight: '1.518rem', // Convert pixel value to rem: 24.29px / 16px = 1.518rem
    border: '0.0625rem outset #FABA4E', // Convert pixel value to rem: 1px / 16px = 0.0625rem
    position: 'relative',
    top: '7.5rem', // Convert pixel value to rem: 120px / 16px = 7.5rem
  },
  icon: {
    width: '1.5rem', // Convert pixel value to rem: 24px / 16px = 1.5rem
    height: '1.5rem', // Convert pixel value to rem: 24px / 16px = 1.5rem
    position: 'relative',
    right: '0.75rem', // Convert pixel value to rem: 12px / 16px = 0.75rem
  }
};



//origin version

// import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import { QrScanner } from '@yudiel/react-qr-scanner';
// import { setQrCodeResult } from '../redux/qrcodeSlice';
// import { useSelector, useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { storeJson } from '../redux/jsonSlice';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCamera } from '@fortawesome/free-solid-svg-icons';



// const ViewFinder = () => {
//   const navigate = useNavigate()
//   const [jsonData, setJsonData] = useState(null);
//   const [showScanner, setShowScanner] = useState(false);
//   const qrCodeResult = useSelector((state) => state.qrcodeStore.qrCodeResult);
//   const currentPlayer = useSelector((state) => state.playerStore.currentPlayer);
//   const dispatch = useDispatch();

//   const handleDecode = (result) => {
//     dispatch(setQrCodeResult(result));
//     console.log(result); // You can remove this console.log if not needed

//       const exploreLocation = async (location) => {
//         try {
//           const location = {
//             "location": qrCodeResult,
//             "user": currentPlayer,
//             "params": {} 
//           };
    
//           console.log("Json is: " + location.location)
    
//           const response = await axios.post('https://vgvqf6srb1.execute-api.ap-east-1.amazonaws.com/dev/visitLocation',
//             location
//           );
//           console.log(response.data)
    
//           dispatch(storeJson(response.data));
//           setJsonData(response.data);
//           // console.log(jsonData)
//           if(response.data.result){
//             navigate('/LocationPage'); // Programmatically navigate to "/LocationPage"
//           }
//           else
//           {
//             console.log("No data")
//           }
        
    
//         } catch (error) {
//           console.error('Error visiting location:', error);
//         }
//       };
   

//     exploreLocation()
  

   
//   };

//   // return (
//   //     <div
//   //       style={{
//   //         width: '200px',
//   //         height: '200px',
//   //         borderRadius: '10px',
//   //         backgroundColor: 'rgba(255, 165, 0, 0.5)',
//   //         position: 'relative',
//   //       }}
//   //     >
//   //       {!qrCodeDetected && (
//   //         <>
//   //           <Button
//   //             onClick={() => setQrCodeDetected(true)}
//   //             style={{
//   //               position: 'absolute',
//   //               top: '50%',
//   //               left: '50%',
//   //               transform: 'translate(-50%, -50%)',
//   //               zIndex: 1,
//   //             }}
//   //           >
//   //             Scan Location
//   //           </Button>
//   //           <QrScanner
//   //             onDecode={handleDecode}
//   //             onError={(error) => console.log(error?.message)}
//   //           />
//   //         </>
//   //       )}

//   //       {qrCodeDetected && (
//   //         <Link to="/next-page">
//   //           <Button
//   //             style={{
//   //               position: 'absolute',
//   //               top: '50%',
//   //               left: '50%',
//   //               transform: 'translate(-50%, -50%)',
//   //               zIndex: 1,
//   //             }}
//   //           >
//   //             Go to Next Page
//   //           </Button>
//   //         </Link>
//   //       )}
//   //     </div>
//   //   );

//   return (
//     <div
//       style={scanContainer}
//     >
//       {!showScanner && (
//         <Button style={button.scanLocation} onClick={() => setShowScanner(true)}>
//           <FontAwesomeIcon icon={faCamera} style={button.icon}></FontAwesomeIcon> Scan Location
//           </Button>
//       )}

//       {showScanner && (
//         <QrScanner
//           onDecode={handleDecode}
//           onError={(error) => console.log(error?.message)}
 
//         />
//       )}

//     </div>
//   );
// };

// export default ViewFinder;

// const scanContainer = {
//   width: '328px',
//   height: '328px',
//   borderRadius: '8px',
//   border: '1px solid #F2994A',
//   padding: '16px 16px',
//   backgroundColor: '#FFFFFF33',
//   position: 'relative',
//   top: '30px',
//   left: '17px',
// };

// const button = {
//   scanLocation: {
//     width: '296px',
//     height: '48px',
//     borderRadius: '24px',
//     border: '1px solid',
//     padding: '12px 8px',
//     marginBottom: '8px',
//     backgroundColor: '#DD7C2B',
//     fontFamily: 'Karantina, system-ui',
//     fontWeight: '400',
//     fontSize: '24px',
//     lineHeight: '24.29px',
//     border: '1px outset #FABA4E',
//     position: 'relative',
//     top: '120px',
//   },
//   icon: {
//     width: '24px',
//     height: '24px',
//     position: 'relative',
//     right: '12px',
//   }
// };