import React from 'react';
import '../../css/style.css'
import { Container, Row, Col } from 'react-bootstrap';
import CharacterIcon from './CharacterIcon';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { setCurrentPlayer } from '../redux/playersSlice';
import { useNavigate } from 'react-router-dom';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatePlayer } from '../redux/playersSlice';

import ButtonSound from '../../sounds/Start.wav';

const NameBox = ({ numberOfPlayers, backgroundColor, player, routingPage, checkProperty }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    console.log(numberOfPlayers)
    console.log(backgroundColor)
    //console.log("NameBox" + player.playerName)

    function handleClick() {
        console.log(player.playerName);
        playSound()
        // TODO: Set the current player in redux to this player 
        dispatch(setCurrentPlayer(player));
    }

    const playSound = () => {
        const audio = new Audio(ButtonSound);
        audio.play();
      }

    return (
        <Row style={RowStyle}>
            <Col>
                <Row>
                    <Col xs={9}>
                        <div className="layout" style={layout}>
                            <div className="row">
                                <div className="col-3">
                                    <CharacterContainer>
                                        <CharacterIcon numberOfPlayer={numberOfPlayers} backgroundColor={backgroundColor} />
                                    </CharacterContainer>
                                </div>
                                <div className="col-9" style={nameContainer}>
                                    <div style={playerNameStyle} onClick={() => {
                                        checkProperty && navigate('/Properties');
                                        dispatch(setCurrentPlayer(player));
                                    }}>{player.playerName}</div>
                                </div>
                            </div>


                        </div>
                    </Col>
                    <Col>
                        <Link to={routingPage}>
                            <Button style={squareButton} onClick={handleClick} className="hoverEnlarge">
                                <FontAwesomeIcon icon={faArrowRight} style={iconStyle} />
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}

export default NameBox;

const layout = {
    width: '272px',
    height: '48px',
    borderRadius: '4px',
    backgroundColor: '#584242',
};

const CharacterContainer = styled.div`
    position: relative;
  `;

const RowStyle = {
    marginBottom: '40px',
    position: 'relative',
    top: '75px',
    left: '20px',
};

const squareButton = {
    width: '48px',
    height: '48px',
    borderRadius: '4px',
    backgroundColor: '#DD7C2B',
    border: 'none',
};

const iconStyle = {
    width: '24px',
    height: '24px',
    marginTop: '5px',
};

const nameContainer = {
    color: 'white',
    padding: '12px 8px',
    fontFamily: 'Karantina, system-ui',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '24.29px',
    zIndex: '2',
    position: 'relative',

};

const playerNameStyle = {
    position: 'absolute',
    left: '0',
    width: '100%',
};


