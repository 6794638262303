import React,{useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/OswaldFont.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';

import bullets from '../../images/bullets.png'
import food from '../../images/food.png'
import health from '../../images/health.png'
import fuel from '../../images/fuel.png'
import noise from '../../images/noise.png'
import morale from '../../images/morale.png'
import weapons from '../../images/weapons.png'
import materials from '../../images/weapons.png'
import scientific_research from '../../images/scientific_research.png'
import weapon_designs from '../../images/weapon_designs.png'
import tools from '../../images/tools.png'
import resources from '../../images/resources.png'
import vantage_point from '../../images/vantage_point.png'
import population from '../../images/population.png'
import safety from '../../images/safety.png'

import { WOW } from 'wowjs';
import 'animate.css';

const EncounterResultComponent = () => {
    const rewards = useSelector((state) => state.gameStore.rewards);
    const qrCodeResult = useSelector((state) => state.qrcodeStore.qrCodeResult);
    const jsonFromRedux = useSelector((state) => state.jsonStore.json);
    const currentPlayer = useSelector((state) => state.playerStore.currentPlayer);
    const currentOption = useSelector((state) => state.gameStore.currentOption);

    const countableObjects = ['fuel', 'bullets', 'scientific_research', 'food', 'weapons', 'materials', 'tools', 'resources', 'vantage_point'];

    const imageMap = {
        food: food,
        health: health,
        fuel: fuel,
        bullets: bullets,
        noise: noise,
        morale: morale,
        weapons: weapons,
        materials: materials,
        scientific_research: scientific_research,
        weapon_designs: weapon_designs,
        tools: tools,
        resources: resources,
        vantage_point: vantage_point,
        population: population,
        safety: safety
        // Add more key-image mappings as needed
    };

    useEffect(() => {
        new WOW().init();
      }, []);
    return (
        <div style={component.container} className={`wow animate__animated animate__headShake `} >
            <Container>
                <Row>
                    <Col>
                        <p style={styles.heading}>Encounter result</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr style={styles.break} />
                    </Col>
                </Row>

                {rewards.map((reward, index) => (
                    <Row>
                        <Col>

                            <div class="container" className={`wow animate__animated animate__fadeIn `} data-wow-delay = {`${index}s`}>
                                <div class="row">
                                    <div class="col-6">
                                        <img src={imageMap[reward.key]} alt="Reward Image" style={component.gas} />
                                        {/* <img src={gas} alt="gas" style={component.gas} /> */}
                                    </div>
                                    <div class="col-6">
                                        <p style={styles.upperText} >  {countableObjects.includes(reward.key) ? (
                                            <>
                                                {reward.value > 0 ? `You get ${reward.value} ${reward.key}` : `You lost ${Math.abs(reward.value)} ${reward.key}`}
                                            </>
                                        ) : (
                                            <>
                                                {reward.value > 0 ? `${reward.key.charAt(0).toUpperCase() + reward.key.slice(1)} increases in ${reward.value}` : `${reward.key.charAt(0).toUpperCase() + reward.key.slice(1)} decreases in ${Math.abs(reward.value)}`}
                                            </>
                                        )}</p>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                ))}

            </Container>
        </div>
    );
};

export default EncounterResultComponent;


const component = {
    container: {
        width: '329px',
        height: 'auto',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF33',
        border: '1px solid #F2994A',
        padding: '16px',
        gap: '16px',
        position: 'relative',
        top: '30px',
        left: '20px',

    },
    gas: {
        width: '30px',
        height: '30px',
        position: 'relative',
        // top: '-20px',
        left: '40px',
    },
    // sound: {
    //     width: '30px',
    //     height: '30px',
    //     position: 'relative',
    //     top: '-20px',
    //     left: '25px',
    // }
}

const styles = {
    upperText: {
        fontFamily: 'Oswald',
        fontWeight: 500,
        fontSize: '15px',
        // lineHeight: '29.64px',
        // textAlign: 'center',
        // color: '#F2F2F2',
        position: 'relative',
         top: '3px',
        right: '55px',
        width: '200px'
    },
    lowerText: {
        fontFamily: 'Oswald',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '29.64px',
        textAlign: 'center',
        color: '#F2F2F2',
        position: 'relative',
        top: '-20px',
        right: '35px',
        width: '155px',
    },
    heading: {
        fontFamily: 'Karantina',
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '32.38px',
        textAlign: 'center',
    },
    break: {
        width: '296px',
        borderWidth: '2px',
        borderColor: '#FFFFFF4D',
        position: 'relative',
        top: '-5px',
        right: '7px',
    }
}