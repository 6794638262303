import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import AppButton from '../uiElements/AppButton';
import EncounterButton from '../uiElements/EncounterButton'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import MenuComponent from '../uiElements/MenuComponent';

import FillBox from '../uiElements/FillBox';

import { WOW } from 'wowjs';
import 'animate.css';
import '../../css/animationEffect.css';
import ButtonSound from '../../sounds/Start.wav';

const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;

function MenuPage() {

    const [modalOpen, setModalOpen] = useState(false);
    const playSound = () => {
        const audio = new Audio(ButtonSound);
        audio.play();
      }
    useEffect(() => {
        new WOW().init();
    }, []);
    return (
        <Container >
            <Row>
                <Col sm></Col>
                <Col sm >
                    <div style={styles.backgroundColor}>

                        <h1 style={styles.heading} >MENU</h1>

                       
                            <Link to='/MainPage'>
                                <Button style={styles.xButtonContainer}>
                                    <FontAwesomeIcon icon={faXmark} style={{ width: '1.5rem', height: '1.5rem' }} />
                                </Button>
                            </Link>

                            <div className="wow animate__animated animate__fadeInUp" data-wow-duration = "0.5s">
                            <div className="container" style={{ position: 'relative', top: '9.375rem', left: '1.25rem', }}>
                                <div className="row">
                                    <div className="col">
                                        <Link to='/MainPage'><Button style={styles.resumeGameButton} className="hoverEnlarge" onClick={playSound} >RESUME GAME</Button></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Button onClick={() => { setModalOpen(true);  playSound();}} style={styles.leaveGameButton} className="hoverEnlarge"  >LEAVE GAME</Button>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p style={styles.text}>You will loose the the progress of the current game</p>
                                    </div>
                                </div>
                            </div>
                            {modalOpen && <MenuComponent setOpenModal={setModalOpen} />}
                        </div>
                    </div>
                </Col>
                <Col sm></Col>
            </Row>
        </Container>
    );
}

export default MenuPage;

const styles = {
    backgroundColor: {
        width: '25rem', // Converted from '400px'
        height: '45.9375rem',
        backgroundColor: '#2D0B0A',
    },
    heading: {
        width: '20.5rem', // Converted from '328px'
        height: '3.063rem', // Converted from '49px'
        borderRadius: '1.5rem', // Converted from '24px'
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '3rem', // Converted from '48px'
        lineHeight: '3.073rem', // Converted from '48.58px'
        textAlign: 'center',
        color: '#FFFFFF',
        position: 'relative',
        top: '2rem', // Converted from '32px'
        left: '2.375rem', // Converted from '38px'
    },
    xButtonContainer: {
        position: 'relative',
        top: '-2.5rem', // Converted from '-40px'
        left: '21.25rem', // Converted from '340px'
        backgroundColor: 'transparent',
        border: 'none'
    },
    leaveGameButton: {
        width: '20.5rem', // Converted from '328px'
        height: '3rem', // Converted from '48px'
        borderRadius: '1.5rem', // Converted from '24px'
        border: '1px solid',
        padding: '0.857rem 0.571rem', // Converted from '12px 8px'
        marginBottom: '0.571rem', // Converted from '8px'
        backgroundColor: '#EB5757',
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '1.5rem', // Converted from '24px'
        lineHeight: '1.543rem', // Converted from '24.29px'
        border: '1px outset #FCB1B1',
    },
    resumeGameButton: {
        width: '20.5rem', // Converted from '328px'
        height: '3rem', // Converted from '48px'
        borderRadius: '1.5rem', // Converted from '24px'
        border: '1px solid',
        padding: '0.857rem 0.571rem', // Converted from '12px 8px'
        marginBottom: '1.714rem', // Converted from '24px'
        backgroundColor: '#DD7C2B',
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '1.5rem', // Converted from '24px'
        lineHeight: '1.543rem', // Converted from '24.29px'
        border: '1px outset #FABA4E',
    },
    text: {
        width: '16.438rem', // Converted from '263px'
        height: '1.25rem', // Converted from '20px'
        borderRadius: '1.5rem', // Converted from '24px'
        fontFamily: 'Karantina, system-ui',
        fontWeight: '400',
        fontSize: '1.25rem', // Converted from '20px'
        lineHeight: '1.262rem', // Converted from '20.24px'
        textAlign: 'center',
        color: '#E0E0E0',
        position: 'relative',
        left: '2.188rem', // Converted from '35px'
    }
}



//origin verison

// import React, { useState } from "react";
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import '../../css/style.css';
// import AppButton from '../uiElements/AppButton';
// import EncounterButton from '../uiElements/EncounterButton'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faXmark } from '@fortawesome/free-solid-svg-icons';
// import MenuComponent from '../uiElements/MenuComponent';

// import FillBox from '../uiElements/FillBox';

// const TitleText = styled.h1`
//   position: absolute;
//   top: ${props => props.top};
//   left: 50%;
//   transform: translateX(-50%);
//   color: white;
//   font-size: ${props => props.fontSize};
//   text-align: center;
//   z-index: 1;
// `;

// function MenuPage() {

//     const [modalOpen, setModalOpen] = useState(false);
//     return (
//         <Container>
//             <Row>
//                 <Col sm></Col>
//                 <Col sm>
//                     <div style={styles.backgroundColor}>
//                         <h1 style={styles.heading}>MENU</h1>

//                         <Link to = '/MainPage'>
//                         <Button style={styles.xButtonContainer}>
//                             <FontAwesomeIcon icon={faXmark} style={{ width: '24px', height: '24px' }} />
//                         </Button>
//                         </Link>

//                         <div className="container" style={{ position: 'relative', top: '150px', left: '20px', }}>
//                             <div className="row">
//                                 <div className ="col">
//                                     <Link to = '/MainPage'><Button style={styles.resumeGameButton}>RESUME GAME</Button></Link>
//                                 </div>
//                             </div>
//                             <div className ="row">
//                                 <div className ="col">
//                                     <Button onClick={() => { setModalOpen(true); }} style={styles.leaveGameButton}>LEAVE GAME</Button>

//                                 </div>
//                             </div>
//                             <div className ="row">
//                                 <div className ="col">
//                                     <p style={styles.text}>You will loose the the progress of the current game</p>
//                                 </div>
//                             </div>
//                         </div>
//                         {modalOpen && <MenuComponent setOpenModal={setModalOpen} />}

//                     </div>
//                 </Col>
//                 <Col sm></Col>
//             </Row>
//         </Container>
//     );
// }

// export default MenuPage;

// const styles = {
//     backgroundColor: {
//         width: '400px',
//         height: '685px',
//         backgroundColor: '#2D0B0A',
//     },
//     heading: {
//         width: '328px',
//         height: '49px',
//         borderRadius: '24px',
//         fontFamily: 'Karantina, system-ui',
//         fontWeight: '400',
//         fontSize: '48px',
//         lineHeight: '48.58px',
//         textAlign: 'center',
//         color: '#FFFFFF',
//         position: 'relative',
//         top: '32px',
//         left: '38px',
//     },
//     xButtonContainer: {
//         position: 'relative',
//         top: '-40px',
//         left: '340px',
//         backgroundColor: 'transparent',
//         border: 'none'
//     },
//     leaveGameButton: {
//         width: '328px',
//         height: '48px',
//         borderRadius: '24px',
//         border: '1px solid',
//         padding: '12px 8px',
//         marginBottom: '8px',
//         backgroundColor: '#EB5757',
//         fontFamily: 'Karantina, system-ui',
//         fontWeight: '400',
//         fontSize: '24px',
//         lineHeight: '24.29px',
//         border: '1px outset #FCB1B1',
//     },
//     resumeGameButton: {
//         width: '328px',
//         height: '48px',
//         borderRadius: '24px',
//         border: '1px solid',
//         padding: '12px 8px',
//         marginBottom: '24px',
//         backgroundColor: '#DD7C2B',
//         fontFamily: 'Karantina, system-ui',
//         fontWeight: '400',
//         fontSize: '24px',
//         lineHeight: '24.29px',
//         border: '1px outset #FABA4E',
//     },
//     text: {
//         width: '263px',
//         height: '20px',
//         borderRadius: '24px',
//         fontFamily: 'Karantina, system-ui',
//         fontWeight: '400',
//         fontSize: '20px',
//         lineHeight: '20.24px',
//         textAlign: 'center',
//         color: '#E0E0E0',
//         position: 'relative',
//         left: '35px',
//     }
// }