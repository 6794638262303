import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import gameReducer from './gameSlice';
import playersReducer from './playersSlice'
import qrcodeSlice from './qrcodeSlice';
import jsonSlice from './jsonSlice';


export const store = configureStore({
    reducer: {
        playerStore: playersReducer,
        gameStore: gameReducer,
        qrcodeStore: qrcodeSlice,
        jsonStore: jsonSlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger)
})
