import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import AppButton from '../uiElements/AppButton';
import EncounterButton from '../uiElements/EncounterButton'

import FillBox from '../uiElements/FillBox';

const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;

function LeaveConfirmPage() {
    return (
      <div className="App">
      <header className="App-header">
        <div className="deadzone-container">
          <div className="background-image">
            <TitleText top="5vw" fontSize="6vw">MENU</TitleText>
            <TitleText top="20vw" fontSize="6vw">Do you want to leave the game?</TitleText>
            <TitleText top="60vw" fontSize="6vw"> <AppButton text="LEAVE" color="primary" to="/" />
            <AppButton text="CANCEL" color="primary" to="/MenuPage" /></TitleText>
           

          </div>
        </div>
      </header>
    </div>
    );
  }

export default LeaveConfirmPage;