import './App.css';
import { HashRouter, Routes, Link, Route } from 'react-router-dom'


import CoverPage from './components/pages/CoverPage';
import NumberOfPlayerPage from './components/pages/NumberOfPlayerPage';
import PlayersNamePage from './components/pages/PlayersNamePage';
import IntroPage from './components/pages/IntroPage';
import MainPage from './components/pages/MainPage';
import ContinueCampaignPage from './components/pages/ContinueCampaignPage';
import ScanLocationPage from './components/pages/ScanLocationPage';
import LocationPage from './components/pages/LocationPage';
import EncounterResultPage from './components/pages/EncounterResultPage';
import MenuPage from './components/pages/MenuPage';
import LeaveConfirmPage from './components/pages/LeaveConfirmPage';
import ZombieAttackPage from './components/pages/ZombieAttackPage';
import EnterValuePage from './components/pages/EnterValuePage';
import AttackResultPage from './components/pages/AttackResultPage';
import Properties from './components/pages/Properties';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<CoverPage />} />
        <Route path='/NumberOfPlayersPage' element={<NumberOfPlayerPage />} />
        <Route path='/PlayersNamePage' element={<PlayersNamePage />} />
        <Route path='/IntroPage' element={<IntroPage />} />
        <Route path='/MainPage' element={<MainPage />} /> 
        <Route path='/ContinueCampaignPage' element={<ContinueCampaignPage />} />
        <Route path='/ScanLocationPage' element={<ScanLocationPage />} />
        <Route path='/LocationPage' element={<LocationPage />} />
        <Route path='/EncounterResultPage' element={<EncounterResultPage />} />
        <Route path='/MenuPage' element={<MenuPage />} />
        <Route path='/LeaveConfirmPage' element={<LeaveConfirmPage />} />
        <Route path='/ZombieAttackPage' element={<ZombieAttackPage />} />
        <Route path='/EnterValuePage' element={<EnterValuePage />} />
        <Route path='/AttackResultPage' element={<AttackResultPage />} />
        <Route path='/Properties' element={<Properties />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
