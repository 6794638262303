import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import AppButton from '../uiElements/AppButton';

import bullets from '../../images/bullets.png'
import food from '../../images/food.png'
import health from '../../images/health.png'
import fuel from '../../images/fuel.png'
import noise from '../../images/noise.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FillBox from '../uiElements/FillBox';
import LocationPageOverlay from '../uiElements/LocationPageOverlay';
import EncounterResultContentBar from '../uiElements/EncounterResultContentBar';

const TitleText = styled.h1`
  position: absolute;
  top: ${props => props.top};
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: ${props => props.fontSize};
  text-align: center;
  z-index: 1;
`;



function EncounterResultPage() {

  const rewards = useSelector((state) => state.gameStore.rewards);
  const qrCodeResult = useSelector((state) => state.qrcodeStore.qrCodeResult);
  const jsonFromRedux = useSelector((state) => state.jsonStore.json);
  const currentPlayer = useSelector((state) => state.playerStore.currentPlayer);
  const currentOption = useSelector((state) => state.gameStore.currentOption);

  const capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string') {
      return 'Please provide a valid string input';
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const countableObjects = ['fuel', 'bullets', 'scientific_research', 'food'];


  const imageMap = {
    food: food,
    health: health,
    fuel: fuel,
    bullets: bullets,
    noise: noise
    // Add more key-image mappings as needed
  };

  return (
    // <div className="App">
    //   <header className="App-header">
    //     <div className="deadzone-container">
    //       <div className="background-image">
    //         <TitleText top="5vw" fontSize="6vw"> {capitalizeFirstLetter(qrCodeResult.toString())}</TitleText>
    //         <TitleText top="15vw" fontSize="3vw">{currentPlayer.playerName}</TitleText>
    //         <TitleText top="30vw" fontSize="3vw"><p style={{ width: '300px' }}>{jsonFromRedux && jsonFromRedux.result.options[currentOption].result_text}</p></TitleText>
    //         <TitleText top="60vw" fontSize="3vw"> Encounter Result:  <div>
    //           {rewards.map((reward, index) => (
    //             <div key={index}>
    //               <img src={imageMap[reward.key]} alt="Reward Image" className="thumbnail" />
    //               {countableObjects.includes(reward.key) ? (
    //                 <>
    //                   {reward.value > 0 ? `You get ${reward.value} ${reward.key}` : `You lost ${Math.abs(reward.value)} ${reward.key}`}
    //                 </>
    //               ) : (
    //                 <>
    //                   {reward.value > 0 ? `${reward.key} increases in ${reward.value}` : `${reward.key} decreases in ${Math.abs(reward.value)}`}
    //                 </>
    //               )}
    //             </div>
    //           ))}

    //         </div></TitleText>

    //         <TitleText top="100vw" fontSize="3vw">
    //           <AppButton text="BACK" color="primary" to="/MainPage" />
    //         </TitleText>
    //       </div>
    //     </div>
    //   </header>
    // </div>

      <Container>
          <Row>
              <Col sm></Col>
              <Col sm>
                  <LocationPageOverlay />
                  <EncounterResultContentBar />
              </Col>
              <Col sm></Col>
          </Row>
      </Container>

  );
}

export default EncounterResultPage;